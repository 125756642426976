import React, { useEffect } from "react";

export function PLAIDModal(props) {

  var myAppId = "472369";
  var environment = ["stage", "production"];
  var apiVersion = "3.0";
  
  React.useEffect(() => {
    openPlaid();
  }, []);
  
  function openPlaid() {
    var options = {
      avoid_micro_deposits: false
    };
    var error = window.WePay.configure(environment[0], myAppId, apiVersion);
    if (error) {
      console.log(error);
    }
    var paymentBankLightBox = window.WePay.createPaymentBankLightBox(
      function (data) {
        console.log("plaid event:", data);
        if (paymentBankLightBox.error_code) {
          //error, light box could not be created.
          console.error(paymentBankLightBox);
        }
      },
      { avoid_micro_deposits: true }
    );

    //tokenize plaid response
    paymentBankLightBox
      .tokenize()
      .then(function (response) {
        //payment method token created successfully.
        console.log("token_object:", response);
        props.handleAddBankAccountPLAID(response.id)
        //get the promise response from the console
        console.log('response', JSON.stringify(response));
        //print the token on the page; REMOVE IN PRODUCTION ENVIRONMENT
        var node = document.createElement('div');
        var token = ('response', JSON.stringify(response));
        node.innerHTML = response["id"];
        document.getElementById('tokenBank').appendChild(node);
      })

      //display completed order part
      .then(function closeAddress() {
        var elems = document.querySelector('.collapsible');
        var instances = window.M.Collapsible.init(elems);
        instances.close(2);
      })
      .then(function () {
        document.getElementById('complete-screen').style.display = "block";
      })

      .catch(function (error) {
        //something went wrong while creating the token.
        console.log("error:", error);
      });
  }


  return (
    null
  );
}

export default PLAIDModal;
