//LeftNav.js
import React, { Component } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link, NavLink, withRouter, matchPath } from "react-router-dom";
import "./LeftNav.scss";
import mixpanel from "mixpanel-browser";

export default class LeftNav extends Component {
  render() {
    const ndbId = this.props.user && this.props.user.ndbId.toString();
    const LeftNavItemWithRouter = withRouter(LeftNavItem);
    const submenu = {
      services: [{ label: "Service", icon: "payment", to: "/markets/services" }]}
    return (
      <Menu borderless vertical stackable fixed="left" className={"leftnav"}>
        <Link to="/dashboard">
          <img
            src="/assets/img/logos/2logo-white-2.svg"
            className="logo"
            alt="Leasera"
          />
        </Link>
        <Link to="/dashboard">
          <img
            src="/assets/img/logos/2logo-white-3.svg"
            className="logo-small"
            alt="Leasera"
          />
        </Link>
        <LeftNavItem
          ndbId={ndbId}
          label="Explore"
          icon="dashboard"
          to="/explore"
        />
        <LeftNavItem ndbId={ndbId} label="My Leasera" icon="dashboard" to="/" />
        {/* <LeftNavItem label='My Rental' icon='home' to='/rental'/> */}
        {/* <LeftNavItem label='Applications' icon='home' to='/applications'/> */}
        {/* <LeftNavItem label='Documents' icon='folder open' to='/documents'/> */}
        <LeftNavItem
          ndbId={ndbId}
          label="Maintenance"
          icon="hammer"
          to="/maintenance"
        />
        <LeftNavItemWithRouter
          ndbId={ndbId}
          label="My Market"
          icon="shopping basket"
          to="/markets"
          submenu={submenu.services}
        />
        <LeftNavItem
          ndbId={ndbId}
          label="Payments"
          icon="dollar"
          to="/payments"
        />
        <LeftNavItem
          ndbId={ndbId}
          label="Messages"
          icon="chat"
          to="/messages"
        />
        <LeftNavItem
          ndbId={ndbId}
          label="Schedule"
          icon="calendar alternate"
          to="/Schedule"
        />
        <LeftNavItem disabled label='Trips' icon='plane' to='/trips'/>
      </Menu>
    );
  }
}

class LeftNavItem extends Component {
  isActive = () => {
    if (!this.props.to) return false;
    let exact = false;
    if (this.props.to === "/") {
      exact = true;
    }
  else {
      return false;
    }
  };

  render() {
    const submenuActiveClass = this.isActive() ? "submenu-active" : null;
    const mixTrack = (item) =>
      mixpanel.track("Renter Left Navbar Click", { sub: item });
    return (
      <span className={submenuActiveClass}>
        <NavLink onClick={()=> mixTrack(this.props.label)} exact to={this.props.to}>
          <Menu.Item className={this.props.disabled ? 'disabled' : null}>
            <div className='alignment-container'>
              <span><Icon name={this.props.icon} /> </span>
              <span className='leftnav-label'>{this.props.label}</span>
            </div>
          </Menu.Item>
        </NavLink>
        {this.props.submenu &&  (
          <Menu.Menu className="submenu">
            {this.props.submenu.map((item, i) => (
              <NavLink
                onClick={() => mixTrack(this.props.label)}
                exact
                to={item.to}
                disabled={item.disabled}
                key={i}
              >
                <Menu.Item className={item.disabled ? "disabled" : null}>
                  <span>
                    <Icon name={item.icon} />
                  </span>
                  <span className="leftnav-label">{item.label}</span>
                </Menu.Item>
              </NavLink>
            ))}
          </Menu.Menu>
        ) }
      </span>
    );
  }
}
