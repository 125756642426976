import React, { Component } from "react";
import RllModal from "../../components/Modals/AddInsuranceModal";
import "./Dashboard.scss";
import { withApollo } from "react-apollo";
import get from "lodash/get";
import { qpAddScrollbar, qpTooltipPopover } from "../../utils/misc";
import { PageLoader } from "../../components/Loader/PageLoader";
import { TourSingleLocation, updateTour } from "../../store/person/properties";
import { cancelApplicationGql } from "../../store/person/applications";
import { ageValidation } from "../../utils/common";
import { maintenanceGql } from "../../store/person/maintenance";
import { Carousel } from "react-responsive-carousel";
import mixpanel from "mixpanel-browser";
import prependHttp from "prepend-http";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
import {
  VIEW_APPLICATIONS,
  FAKE_PROPERTY,
  APPLICATIONS_URL,
  PERSON_URL,
  MARKETING,
  TOURS,
  BG_CHECK_URL,
  LEASE_RENTER_LOCAL,
  LEASES_URL,
} from "../../utils/constants";
import { backgroundCheckGql } from "../../store/person/backgroundCheck";
import {
  leasesGql,
  LeaseGuaranteeOptions,
  sendSelectedCoverage,
  updateLease,
  LeaseGuaranteeStatus,
} from "../../store/person/leases";
import { applicationsGql } from "../../store/person/applications";
import {
  updatePersonGql,
  QueryOffers,
  eventsGql,
} from "../../store/person/person";
import { HomeEmpty } from "../../components/EmptyCards/HomeEmpty";
import LeaseGuaranteeModal from "../../components/Modals/LeaseGuaranteeModal";
import MoveInChecklist from "../../components/Modals/MoveInChecklist";
import HelloSign from "hellosign-embedded";
import { getAuthToken } from "../../store/auth/authUtility";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import Slider from "react-slick";

import {
  parseGraphQLErrors,
  parsePhotos,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import "../../assets/fomantic/dist/semantic.css";
import { ToastContainer, toast } from "react-toastify";
import { getLatLong } from "../../utils/getLatLong";
import MapItModal from "../../components/MapItModal";
import SelectedLeaseModal from "../../components/Modals/SelectedLeaseModal";

import moment from "moment";

import {
  Accordion,
  Button,
  Confirm,
  Grid,
  Label,
  Icon,
  Image,
} from "semantic-ui-react";

const client = new HelloSign({
  clientId: process.env.REACT_APP_HELLOSIGN_ID,
});
const BGC = getClient(BG_CHECK_URL);
const AppNewClient = getClient(APPLICATIONS_URL);
const tourAPI = getClient(TOURS);
const LGAPI = getClient(LEASES_URL);
const personMainClient = getClient(PERSON_URL);
const OffersQuery = getClient(MARKETING);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalProperty: FAKE_PROPERTY,
      leaseGuarantee: [],
      showCalDetails: false,
      selectedLeaseId:null,
      selectedLeaseModal:false,
      selectedLease:'',
      dueDate: "",
      accordion: 0,
      from: null,
      cancelTour: "",
      selectedDate: "",
      transactions: [],
      lgStatuses: [],
      loading: true,
      inactiveLeases: [],
      applications: [],
      onOpen: false,
      open: false,
      BGdateArray: [],
      tourCache: [],
      userAge: null,
      leaseLoader: false,
      terms: false,
      modalOpen: false,
      offers: [],
      message: "",
      isError: false,
      leases: [],
      pendingLeases:[],
      eventCache: [],
      signedAppId: "",
      signatureUrl: "",
      ssn: "",
      ssnInfo: "",
      deepDiveEvent: "",
      ActivePay: false,
      showPassword: false,
      run: false,
      activeIndex: 0,
      showTours: false,
      mapStatus: false,
      selectedMapLoc: null,
      checkedItems: new Map(),
      modalData: null,
    };
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };

  removeNonLease = (location) => {
    mixpanel.track("Renter Lease Action", {
      sub: "Remove Non Leasera Property",
    });
    try {
      personMainClient
        .mutate({
          mutation: updatePersonGql,
          variables: {
            input: {
              address: {
                action: "delete",
                id: location.ndbId,
              },
            },
          },
        })
        .then((res) => {
          window.location.reload();
          this.close();
        })
        .catch((error) => {
          this.close();
          console.log(error);
        });
    } catch (e) {
      this.close();
      console.log(e);
    }
  };

  backgroundCheck = () => {
    BGC.query({
      query: backgroundCheckGql,
    })
      .then((results) => {
        if (results.data) {
          const dates = results.data.backgroundChecks.edges;
          const BGdateArray = [];
          dates.forEach((date) => {
            if (
              moment(date.node.submitted).isBetween(
                moment().subtract(31, "days"),
                moment()
              )
            ) {
              BGdateArray.push(date);
            }
          });
          this.setState({ BGdateArray: BGdateArray });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ loading: false });
      });
  };
  LGStatusQuery = (leases) => {
    var LgStatus = leases.filter((lg) => lg.node.guarantee);
    var Lgstate = LgStatus.filter((lg) => lg.node.lgState !== null);
    if (Lgstate.length !== 0) {
      Lgstate.forEach((lease) => {
        LGAPI.query({
          query: LeaseGuaranteeStatus,
          variables: {
            leaseId: lease.node.id,
          },
        })
          .then((results) => {
            const lgStatuse = get(
              results,
              "data.rentGuardStatus.response.result.rent_guard",
              []
            );
            lgStatuse.id = lease.node.id;
            var leases = [...this.state.leases];
            var index = leases.findIndex((obj) => obj.node.id === lgStatuse.id);
            leases[index].lgInfo = lgStatuse;
            this.setState({ leases });
          })
          .catch((e) => {
            console.log(e);
          });
      });
    }
  };

  fetchApplications = () => {
    try {
      this.props.applicationManager
        .query({
          query: applicationsGql,
        })
        .then((response) => {
          const applications = get(response, "data.applications.edges", []);
          const newArr = applications.filter(
            (ele) =>
              ele.node.status !== "Cancelled" || ele.node.status !== "Denied"
          );
          const setNew = newArr.filter((ele) => !ele.node.onBoardScript);
          this.setState({ applications: setNew });
        })
        .catch((error) => {});
    } catch (e) {
      console.log(e);
    }
  };

  handleClick = (index, close = false) => {
    const element = document.getElementById(index);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    this.setState({ activeIndex: !close ? index : -1 });
  };

  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      stepIndex: 1,
      run: true,
    });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === "close"
    ) {
      this.setState({ run: false });
    }
  };

  componentDidMount() {
    qpAddScrollbar(".card-media-list", "dark");

    qpTooltipPopover();
    this.fetchApplications();
    this.getLeases();
    this.fetchTours();
    this.backgroundCheck();
    this.getMaintenanceRequests();
    this.getOffers();

    this.cancelApp = this.cancelApp.bind(this);
    client.on("sign", () => {
      const closeBtn = document.getElementsByClassName(
        "x-hellosign-embedded__modal-close-button"
      );
      closeBtn.length > 0 && closeBtn[0].click();
      let apps = this.state.applications;
      if (apps.length > 0) {
        const foundIndex = apps.findIndex(
          (app) => app.node.ndbId === this.state.signedAppId
        );
        if (foundIndex > -1) apps[foundIndex].node.signedLease = true;
      }
      this.setState({
        applications: apps,
        message:
          "Congratulations! Lease agreement has been signed. Find the agreement under Documents tab.",
        isError: false,
      });
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.primaryId ? prevProps.primaryId : "";
    if (nodeData !== this.state.primaryId) {
      this.setState({ primaryId: nodeData }, () => {
        // this.getLeases();
      });
    }
  }

  closeMessage = () => {
    this.setState({ message: "" });
  };
  
  viewLeaseOffer = (item) => {
    const tempo = 'TGVhc2U6IDYwNw=='
    this.setState({selectedLeaseId:item})
    fetch(LEASE_RENTER_LOCAL + tempo , {
      method: "GET",
      headers: {
        Authorization: getAuthToken(),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        this.setState({selectedLease:url})
        // setPreviewLoader(false);
        // window.open(url, "_blank");
      })
      .catch((error) => {
        console.error(error);
        // setPreviewLoader(false);
      });
  };

  submitAge = () => {
    this.setState({ loading: true });
    mixpanel.track("Renter Account Action", {
      sub: "Update DOB",
    });
    try {
      personMainClient
        .mutate({
          mutation: updatePersonGql,
          variables: {
            input: {
              dateOfBirth: moment(this.state.userAge).format("YYYY-MM-DD"),
            },
          },
        })
        .then((response) => {
          this.setState({ loading: false });
          toastSuccessMsg("Profile updated");
          this.props.getPersonInfo();
          return false;
        })
        .catch((e) => {
          toastFailMsg(parseGraphQLErrors(e));
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  getOffers() {
    try {
      OffersQuery.query({
        query: QueryOffers,
      })
        .then((response) => {
          const array = response.data.personServices.edges;
          this.setState({
            offers: array,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  getMaintenanceRequests() {
    this.setState({ loading: true });
    const roleType = "renter";
    const statusType = [
      "Submitted",
      "Processing",
      "Scheduled",
      "Entered",
      "Completed",
      "Canceled",
      "Assigned",
      "Approval Needed",
      "Approved",
    ];
    try {
      this.props.maintenanceClient
        .query({
          query: maintenanceGql,
          variables: {
            role: roleType,
            status: statusType,
            // location: this.props.primaryId
          },
        })
        .then((response) => {
          this.setState({ loading: false });
          this.setState({
            requests: get(response, "data.maintenanceRequest.edges", null),
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          // this.setMessage(`Oops! There was a problem fetching maintenance requests: ${parseGraphQLErrors(error)}`, false)
        });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  }

  redirectToProfile = () => {
    this.props.history.push(`/profile?${VIEW_APPLICATIONS}`);
  };

  setModalProperty = (modalProperty, from = null) =>
    this.setState({ modalProperty, from });

  cancelApp = (id = null) => {
    this.setState({ leaseLoader: true });
    mixpanel.track("Renter Application Action", {
      sub: "Cancel Application",
    });
    try {
      AppNewClient.mutate({
        mutation: cancelApplicationGql,
        variables: { input: { id: id || this.state.modalProperty.ndbId } },
      })
        .then((res) => {
          this.setState({ leaseLoader: false });
          if (!res.errors) {
            this.setState({
              message: "Application has been canceled",
              isError: false,
            });
            const filterId = id || this.state.modalProperty.ndbId;
            const applications = this.state.applications.filter(
              (app) => app.node.ndbId !== filterId
            );
            this.setState({
              applications,
            });
          } else {
            this.setState({ message: res.errors.message, isError: true });
          }
        })
        .catch((error) => {
          this.setState({ leaseLoader: false });
          this.setState({
            message: `Oops! There was a problem. ${parseGraphQLErrors(
              error
            )}, please try again.`,
            isError: true,
          });
        });
    } catch (e) {
      this.setState({ leaseLoader: false });
    }
  };

  fetchLeaseAgreement = async (signIds, appId) => {
    const signId = signIds.filter(
      (id) =>
        id.email === (this.props.user && this.props.user.email).toLowerCase()
    );
    if (signId.length === 0) {
      this.setState({ message: "No signature url available", isError: true });
    } else {
      this.setState({ leaseLoader: true });
      await fetch(
        `https://us-central1-leasera-200719.cloudfunctions.net/person_hellosign?signature_id=${signId[0].sid}`,
        {
          method: "GET",
          headers: {
            Authorization: getAuthToken(),
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            this.setState({ message: response.status, isError: true });
          }
          this.setState({ leaseLoader: false });
          return response;
        })
        .then((result) => result.json())
        .then((data) => {
          this.setState({ leaseLoader: false });
          client.open(data.sign_url, {
            testMode: true,
          });
          this.setState({
            signatureUrl: data.sign_url,
            signedAppId: appId,
          });
        });
    }
  };

  fetchTours = () => {
    try {
      personMainClient
        .query({
          query: eventsGql,
        })
        .then((res) => {
          if (res.data) {
            const events =
              res.data.personCalendar && res.data.personCalendar.edges;
            this.setState({ tourCache: events });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  applicationFee = () => {
    this.props.history.push({
      pathname: "/payments/applicationFee",
    });
  };

  success = () =>
    toast.success("Event Canceled!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  removeTour(i) {
    const { cancelTour } = this.state;
    const Tours = this.state.tourCache.slice();
    const index = i;
    mixpanel.track("Renter Application Action", {
      sub: "Cancel Tour",
    });
    try {
      tourAPI
        .mutate({
          mutation: updateTour,
          variables: {
            input: {
              tour: {
                id: cancelTour,
                status: "cancel",
                comment: "Cancel",
              },
            },
          },
        })
        .then((response) => {
          Tours.forEach((event) => {
            if (index === event) {
              Tours.splice(index, 1);
              return Tours;
            }
          });
          this.handleClose();
          this.success();
          this.setState({
            tourCache: Tours,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  getLeases = () => {
    if (this.props.user) {
      mixpanel.track("Renter Render", {
        sub: "Dashboard",
      });
    }
    try {
      this.props.leasesClient
        .query({
          query: leasesGql,
        })
        .then((response) => {
          if (!response.error) {
            const leases = get(response, "data.leases.edges", []);
            const activeLease = leases.filter(
              (lease) =>
                lease.node.status === "move_in" ||
                lease.node.status === "in_progress" ||
                lease.node.status === "signed" ||
                lease.node.status === "renewal" ||
                lease.node.status === "renewed"
            );
            const inactiveLease = leases.filter(
              (lease) => lease.node.status === "move_out"
            );
            const pendingLease = leases.filter(
              (lease) => lease.node.status === "created"
            );
            const localProperty = this.props.user.addresses;
            if (localProperty !== null) {
              const currentProperty = localProperty
                .filter((Property) => Property.current === true)
                .map((node) => {
                  const property = { node };
                  return property;
                });
              currentProperty.forEach((property) => {
                activeLease.push(property);
              });
            }
            this.setState({
              leases: activeLease,
              pendingLeases:pendingLease,
              inactiveLeases: inactiveLease,
              dueDate:
                response.leases && response.leases.edges.length > 0
                  ? response.leases.edges[0].node.dueDate
                  : "",
            });
            this.fetchLeaseGuaranteeOptions(leases);
            this.LGStatusQuery(activeLease);
          }
        })
        .catch((error) => {});
    } catch (e) {
      console.log(e);
    }
  };

  fetchLeaseGuaranteeOptions = (leases) => {
    leases
      .filter((ele) => ele.node.guarantee && ele.node.lgState === null)
      .forEach((lease) => {
        LGAPI.query({
          query: LeaseGuaranteeOptions,
          variables: {
            leaseId: lease.node.id,
          },
        })
          .then((res) => {
            const { leaseGuarantee } = this.state;
            if (!res.data.rentGuardPrice.response.error) {
              const item =
                res.data.rentGuardPrice.response &&
                res.data.rentGuardPrice.response.result.final_coverages;
              item &&
                item.forEach((ele) => {
                  leaseGuarantee.push(
                    ele && {
                      key: lease.node.id,
                      text: ele.coverage,
                      value: ele.coverage,
                      price: ele.price,
                    }
                  );
                });
              this.setState({ leaseGuarantee });
            } else {
              toastFailMsg(res.data.rentGuardPrice.response.error);
            }
          })
          .catch((error) => {});
      });
  };

  setSelectedLGOption = async (text, id) => {
    mixpanel.track("Renter Lease Action", {
      sub: "LG Submit",
    });
    try {
      await LGAPI.mutate({
        mutation: sendSelectedCoverage,
        variables: {
          input: {
            rentGuard: {
              leaseId: id,
              coverage: text,
            },
          },
        },
      });
      this.getLeases();
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
    }
  };
  photoCheck = (locPhoto, unitPhoto) => {
    if (locPhoto && locPhoto.length >= 1) {
      return (
        <img
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
          src={locPhoto[0]}
          alt=""
        />
      );
    } else if (unitPhoto && unitPhoto.length >= 1) {
      return (
        <img
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
          src={unitPhoto[0]}
          alt=""
        />
      );
    } else {
      return <img src="assets/img/dashboard/property-image.png" alt="" />;
    }
  };

  photoCheckPrimary = (locPhoto, unitPhoto) => {
    if (locPhoto && locPhoto.length !== 0) {
      return (
        <Carousel
          showArrows={false}
          className={"carousel-dashboard "}
          autoPlay
          interval="5000"
          infiniteLoop
          showThumbs={false}
          showIndicators={false}
        >
          {locPhoto &&
            locPhoto.map((photo) => {
              return (
                <img
                  src={photo}
                  data-holder-rendered="true"
                  className="mySlides"
                  alt="img"
                />
              );
            })}
        </Carousel>
      );
    } else if (unitPhoto && unitPhoto.length !== 0) {
      return (
        <Carousel
          showArrows={false}
          className={"carousel-dashboard "}
          autoPlay
          interval="5000"
          infiniteLoop
          showThumbs={false}
          showIndicators={false}
        >
          {unitPhoto &&
            unitPhoto.map((photo, key) => {
              return (
                <img
                  key={key}
                  src={photo}
                  data-holder-rendered="true"
                  className="mySlides"
                  alt="img"
                />
              );
            })}
        </Carousel>
      );
    }
    // else if
    else {
      return (
        <img
          src="/assets/img/renter-banner.jpg"
          data-holder-rendered="true"
          alt="img"
        />
      );
    }
  };

  setSkipLGOption = async (event, checked, id) => {
    mixpanel.track("Renter Lease Action", {
      sub: "Skip LG",
    });
    try {
      await LGAPI.mutate({
        mutation: updateLease,
        variables: {
          input: {
            lease: {
              leaseId: id,
              skipGuarantee: checked,
            },
          },
        },
      });
      this.getLeases();
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
    }
  };

  toModal = (request) => {
    if (request !== null) {
      this.setState({
        modalData: request,
      });
    }
  };

  showAllTours = () => {
    this.setState({ showTours: !this.state.showTours });
  };

  goToAppFinish = (e) => {
    return this.props.history.push({
      pathname: "/application/:" + e.node.id + ":" + e.node.locationId,
      state: e.node,
    });
  };

  navigateToRenewLease = (lease) => {
    this.props.history.push({
      pathname: `/renewlease/${lease.node.id}`,
    });
  };

  goToApp = (e) => {
    mixpanel.track("Renter Application Action", {
      sub: "View Application Click",
    });
    const coAppCheck =
      e.node.coApplicantsStatuses &&
      e.node.coApplicantsStatuses.filter(
        (e) => e.email === this.props.user.email
      );
    if (coAppCheck.length === 1) {
      this.props.history.push({
        pathname: "/applications/coApp" + e.node.id,
        state: e.node,
      });
    }
    if (coAppCheck.length === 0) {
      this.props.history.push({
        pathname: "/applications/" + e.node.id,
        state: e.node,
      });
    }
  };

  offerClick = () => {
    mixpanel.track("Renter Dashboard Action", { sub: "Offer Deal Click" });
  };
  onTogglePassword = () => {
    if (this.state.showPassword === false) {
      this.setState({ showPassword: true });
    } else {
      this.setState({ showPassword: false });
    }
  };
  openMapModal = async (loc) => {
    if (loc) {
      await getLatLong(loc).then((res) => {
        this.setState({ mapStatus: true, selectedMapLoc: res });
      });
    } else this.setState({ mapStatus: false });
  };

  openSelectedLeaseModal = () => {
    if (this.state.selectedLease) {
      this.setState({selectedLeaseModal:true})
    }
  };

  renderTourData = (tour) => {
    const value = moment(tour.node.date).format("MM/DD/YYYY");
    const localTime = moment(value + " " + tour.node.time, "MM/DD/YYYY HH:mm");
    const local = moment.utc(localTime._i);
    const DDate = local;
    const date = DDate.local().format("MM/DD/YYYY");
    const time = DDate.local().format("hh:mm A");

    return (
      <>
        <div className="tours-section-row">
          <div className="tours-section-left-col">
            <img src="/assets/img/renter-bg.jpg" alt="img" />
          </div>
          <div className="tours-section-right-col">
            <p className="tours-date">{date}</p>
            <p className="tours-time">{time}</p>
            <p className="tours-phone">(425) 758-8490</p>
            <p className="tours-name">{tour.node.name}</p>
            <div className="tours-time-blue">{time}</div>
          </div>
          <p className="tours-address">
            {tour.node.connectionInfo ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={tour.node.connectionInfo}
              >
                {tour.node.connectionInfo}
              </a>
            ) : (
              tour.node.primaryLocation.addresses[0].streetOne +
              " " +
              tour.node.primaryLocation.addresses[0].city
                .charAt(0)
                .toUpperCase() +
              tour.node.primaryLocation.addresses[0].city.slice(1) +
              " " +
              tour.node.primaryLocation.addresses[0].state
                .charAt(0)
                .toUpperCase() +
              tour.node.primaryLocation.addresses[0].state.slice(1) +
              " " +
              tour.node.primaryLocation.addresses[0].zip
            )}
          </p>
          <div className="d-flex align-items-center justify-content-between w-100">
            {/* eslint-disable */}
            <a
              onClick={() =>
                this.setState({ modalOpen: true, cancelTour: tour.node.id })
              }
              style={{ color: "red" }}
            >
              Cancel
            </a>
            {/* eslint-enable */}
            {!tour.node.connectionInfo && (
              <p
                className="tours-map-link w-100"
                onClick={() => this.openMapModal(tour.node)}
              >
                {/* eslint-disable */}
                <a className="pointer">Map this</a>
                {/* eslint-enable */}
              </p>
            )}
          </div>
        </div>
        <Confirm
          className="semanticModal"
          open={this.state.modalOpen}
          onOpen={() => this.setState({ modalOpen: true })}
          onCancel={() => this.handleClose()}
          onConfirm={() => this.removeTour(tour)}
        />
      </>
    );
  };
  setSSN = (e) => {
    this.setState({ ssn: e });
    this.setState({ ssnInfo: e.replace(/-/g, "") });
  };
  ssnChecker = (e) => {
    if (this.state.ssn === e) {
      this.setState({ ActivePay: true });
    } else {
      this.setState({ ActivePay: false });
    }
  };

  handleClose = () => this.setState({ modalOpen: false });

  onOpen = () => {
    this.setState({ onOpen: true });
  };
  onClose = () => {
    this.setState({ onOpen: false, terms: true });
  };
  onCancel = () => {
    this.setState({ onOpen: false });
  };
  handleCheck = () => {
    if (this.state.terms === false) {
      this.setState({ terms: true });
    } else {
      this.setState({ terms: false });
    }
  };

  dashboardList = (values, day) => {
    this.setState({ eventCache: values });
    this.setState({ selectedDate: day });
    var element = document.getElementById("schedule-list");
    element.scrollTo(0, 0);
  };

  showDetails = (event) => {
    this.setState({ showCalDetails: true });
    this.setState({ deepDiveEvent: event });
  };

  render() {
    const { mapStatus, selectedMapLoc, leaseGuarantee } = this.state;
    const { activeIndex } = this.state;
    const { user } = this.props;
    const {
      leases,
      loading,
      inactiveLeases,
      checkedItems,
      requests,
      tourCache,
    } = this.state;

    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <>
        {loading ? (
          <PageLoader text inlineStyle={{ height: "150px", width: "150px" }} />
        ) : (
          <>
            <div className="dashboard-page-container">
              <div className="dashboard-page-left">
                <div className="renter-dashboard-content">
                  {/* <h4>Your applications</h4> */}

                  <Grid stackable columns={3}>
                    <Grid.Row>
                      <Grid.Column>
                        <div className="dashboard-card">
                          <div className="card-header events-header">
                            Events
                          </div>
                          <div className="card-body">
                            <div className="events-list">
                              {tourCache &&
                                tourCache.map((tour) => {
                                  const value = moment(
                                    tour.node.startDate
                                  ).format("MM/DD/YYYY hh:mm");
                                  if (
                                    moment(tour.node.startDate).isSameOrAfter(
                                      new Date(),
                                      "day"
                                    )
                                  ) {
                                    return (
                                      <div className="events-list-card">
                                        <div className="event-title">
                                          {tour.node.eventName}
                                        </div>
                                        <div className="event-description">
                                          <p className="tours-address">
                                            {value}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                            </div>

                            {/* Empty state component */}
                            {tourCache && tourCache.length == 0 && (
                              <div className="dashboard-empty-wrap">
                                <img
                                  src="assets/img/dashboard/empty-events.svg"
                                  alt="event"
                                />
                                <div className="empty-title">No events yet</div>
                                <div className="empty-subtitle">
                                  No events right now, please check back soon.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="dashboard-card">
                          <div className="card-header deals-header">Action</div>
                          <div className="card-body">
                            {user && user.dateOfBirth && (
                              <>
                                <div className="deals-list">
                                  {this.state.pendingLeases.map((item) => {
                                    if (item.node.localLease) {
                                      const local = item.node.localLease;
                                      if (Object.keys(local).length !== 0) {
                                        const name = item?.node?.location?.name
                                        const leaseOffer = item?.node?.id
                                        return (
                                          <div className="deals-list-card">
                                            <div className="wrapper-content">
                                              <div className="application-btn">
                                                <img
                                                  src="/assets/img/dashboard/icons-alert.svg"
                                                  alt="icon"
                                                />
                                                Lease Offer
                                              </div>
                                              <h5>View Lease Offer</h5>
                                              <p>
                                                The Property Manager at {name} has extended a lease offer to you. Click the button to review and Accept.
                                              </p>
                                              <SelectedLeaseModal
                                                  selectedLeaseId={leaseOffer}
                                                  local={local}
                                                  />
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  })}

                                  <div className="deals-list-card">
                                    <Slider {...settings}>
                                      {Array.isArray(this.state.applications) &&
                                        this.state.applications.length > 0 &&
                                        this.state.applications.map((item) => {
                                          const address =
                                            item.node.primaryLocation
                                              .addresses[0];
                                          return (
                                            <div className="wrapper-content">
                                              <h4 className="slider-h4">
                                                {item.node.primaryLocation.name}
                                              </h4>
                                              <h5>
                                                {(
                                                  address.streetOne +
                                                  " " +
                                                  address.city +
                                                  " " +
                                                  address.state +
                                                  " " +
                                                  address.country +
                                                  " " +
                                                  address.zip
                                                ).replaceAll("undefined", " ")}
                                              </h5>
                                              <p>Status: {item.node.status}</p>
                                              {/* eslint-disable */}
                                              {item.node.coApplicantsStatuses &&
                                                this.props.user &&
                                                item.node.coApplicantsStatuses.map(
                                                  (coApp) => {
                                                    /* eslint-enable */
                                                    if (
                                                      coApp &&
                                                      coApp.email ===
                                                        this.props.user.email &&
                                                      coApp.status !==
                                                        "Pending" &&
                                                      item.node.isCoApplicant
                                                    ) {
                                                      return (
                                                        <button
                                                          className="ui button view-app"
                                                          onClick={() =>
                                                            this.goToApp(item)
                                                          }
                                                        >
                                                          View Application
                                                        </button>
                                                      );
                                                    }
                                                  }
                                                )}
                                              {item.node.isCoApplicant ===
                                                false && (
                                                <button
                                                  className="ui button view-app"
                                                  onClick={() =>
                                                    this.goToApp(item)
                                                  }
                                                >
                                                  View Application
                                                </button>
                                              )}
                                              {/* eslint-disable */}
                                              {item.node.coApplicantsStatuses &&
                                                this.props.user &&
                                                item.node.coApplicantsStatuses.map(
                                                  (coApp) => {
                                                    /* eslint-enable */
                                                    if (
                                                      coApp &&
                                                      coApp.email ===
                                                        this.props.user.email &&
                                                      coApp.status === "Pending"
                                                    ) {
                                                      return (
                                                        <button
                                                          className="ui button finish-app"
                                                          onClick={() =>
                                                            this.goToAppFinish(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Finish This
                                                          Application
                                                        </button>
                                                      );
                                                    }
                                                  }
                                                )}
                                            </div>
                                          );
                                        })}
                                    </Slider>
                                  </div>
                                  {/* <div className="deals-list-card">
                                    {
                                      Array.isArray(this.state.applications) &&
                                      this.state.applications.length !== 0 &&
                                      <>

                                        {this.state.BGdateArray &&
                                          this.state.BGdateArray.length === 0 ? (
                                          <div className="wrapper-content">
                                            <div className="application-btn">
                                              <img
                                                src="/assets/img/dashboard/icons-alert.svg"
                                                alt="icon"
                                              />
                                              Application check
                                            </div>
                                            <h5>Get Application Check</h5>
                                            <p>
                                              Secure your applications and skip the
                                              paperwork with an application check.
                                            </p>
                                            <button
                                              className="ui button finish-app"
                                              onClick={() => this.applicationFee()}
                                            >
                                              Proceed to pay
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="wrapper-content">
                                            <div className="application-btn-success">
                                              <Icon disabled name="shield" />
                                              Application check
                                            </div>
                                            <h5>
                                              Application Check Active for{" "}
                                              {moment(
                                                this.state.BGdateArray[0].node.submitted
                                              ).diff(
                                                moment().subtract(31, "days"),
                                                "days"
                                              )}{" "}
                                              Days
                                            </h5>
                                            <p>
                                              Secure your applications and skip the
                                              paperwork with an application check.
                                            </p>
                                            <a
                                              className="ui button finish-app"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href="https://dev.leasera.com/about-us"
                                            >
                                              Learn More
                                            </a>
                                          </div>
                                        )}
                                      </>
                                    }
                                  </div> */}
                                </div>

                                {/* Empty state component */}
                                {Array.isArray(this.state.applications) &&
                                  this.state.applications.length == 0 && (
                                    <div className="dashboard-empty-wrap">
                                      <img
                                        src="assets/img/dashboard/empty-deals.svg"
                                        alt="deals"
                                      />
                                      <div className="empty-title">
                                        No actions yet
                                      </div>
                                      <div className="empty-subtitle">
                                        No actions right now, please check back
                                        soon.
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                            {user && !user.dateOfBirth && (
                              <div className="deals-list">
                                <div className="deals-list-card">
                                  <div className="wrapper-content">
                                    <div className="application-btn">
                                      <img
                                        src="/assets/img/dashboard/icons-alert.svg"
                                        alt="icon"
                                      />
                                      Please Confirm Age
                                    </div>
                                    {/* <h5>Age Confirmation</h5> */}
                                    <p>
                                      It seems we have not confirmed your age.
                                      Please take a moment and update your
                                      profile with your correct date of birth.
                                    </p>
                                    <Datetime
                                      timeFormat={false}
                                      viewMode="days"
                                      input={false}
                                      inputProps={{
                                        readOnly: true,
                                        placeholder: "MM/DD/YYYY",
                                      }}
                                      isValidDate={ageValidation}
                                      // closeOnSelect={true}
                                      onChange={(e) =>
                                        this.setState({ userAge: e })
                                      }
                                    />
                                    <button
                                      className="ui button finish-app"
                                      disabled={!this.state.userAge}
                                      onClick={() => this.submitAge()}
                                    >
                                      Submit DOB -{" "}
                                      {this.state.userAge &&
                                        moment(this.state.userAge).format(
                                          "MM/DD/YYYY"
                                        )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <div className="dashboard-card">
                          <div className="card-header home-header">
                            YOUR HOME
                          </div>
                          <div className="card-body">
                            <div className="home-list">
                              {requests &&
                                requests.map((request) => {
                                  return (
                                    <div className="home-list-card">
                                      <div className="home-title">
                                        Maintenance Request -{" "}
                                        {request.node.status}
                                      </div>
                                      <div className="home-subtitle">
                                        {moment(request.node.created).format(
                                          "MM/DD/YYYY HH:mm"
                                        )}
                                      </div>
                                      <div className="home-description">
                                        {request.node.requestedByDesc}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            {/* Empty state component */}
                            {requests && requests.length == 0 && (
                              <div className="dashboard-empty-wrap">
                                <img
                                  src="assets/img/dashboard/empty-requests.svg"
                                  alt="requests"
                                />
                                <div className="empty-title">
                                  No requests yet
                                </div>
                                <div className="empty-subtitle">
                                  No requests right now, please check back soon.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid stackable columns="equal" className="d-none">
                    <Grid.Column className="left__content">
                      <div className="wrapper-content">
                        <h5>Vacation Rentals</h5>
                        <p>Wherever you're headed, Leasera has you covered.</p>
                        {/* <button type="button" className="view-btn">View Offer</button> */}
                        {/* <img style={{position: 'absolute', right: "20px", bottom: "34px"}} src="/assets/img/dashboard/vacation-renter.png" alt="image" /> */}
                      </div>
                    </Grid.Column>
                    {Array.isArray(this.state.applications) &&
                      this.state.applications.length > 0 && (
                        <>
                          <Grid.Column className="middle__content">
                            <Slider {...settings}>
                              {Array.isArray(this.state.applications) &&
                                this.state.applications.length > 0 &&
                                this.state.applications.map((item) => {
                                  const address =
                                    item.node.primaryLocation.addresses[0];
                                  return (
                                    <div className="wrapper-content">
                                      <h4 className="slider-h4">
                                        {item.node.primaryLocation.name}
                                      </h4>
                                      <h5>
                                        {(
                                          address.streetOne +
                                          " " +
                                          address.city +
                                          " " +
                                          address.state +
                                          " " +
                                          address.country +
                                          " " +
                                          address.zip
                                        ).replaceAll("undefined", " ")}
                                      </h5>
                                      <p>Status: {item.node.status}</p>
                                      {/* eslint-disable */}
                                      {item.node.coApplicantsStatuses &&
                                        this.props.user &&
                                        item.node.coApplicantsStatuses.map(
                                          (coApp) => {
                                            /* eslint-enable */
                                            if (
                                              coApp &&
                                              coApp.email ===
                                                this.props.user.email &&
                                              coApp.status !== "Pending"
                                            ) {
                                              return (
                                                <button
                                                  className="ui button view-app"
                                                  onClick={() =>
                                                    this.goToApp(item)
                                                  }
                                                >
                                                  View Application
                                                </button>
                                              );
                                            }
                                          }
                                        )}
                                      {item.node.isCoApplicant === false && (
                                        <button
                                          className="ui button view-app"
                                          onClick={() => this.goToApp(item)}
                                        >
                                          View Application
                                        </button>
                                      )}
                                      {/* eslint-disable */}
                                      {item.node.coApplicantsStatuses &&
                                        this.props.user &&
                                        item.node.coApplicantsStatuses.map(
                                          (coApp) => {
                                            /* eslint-enable */
                                            if (
                                              coApp &&
                                              coApp.email ===
                                                this.props.user.email &&
                                              coApp.status === "Pending"
                                            ) {
                                              return (
                                                <button
                                                  className="ui button finish-app"
                                                  onClick={() =>
                                                    this.goToAppFinish(item)
                                                  }
                                                >
                                                  Finish This Application
                                                </button>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  );
                                })}
                            </Slider>
                          </Grid.Column>
                          <Grid.Column className="right__content">
                            {this.state.BGdateArray &&
                            this.state.BGdateArray.length === 0 ? (
                              <div className="wrapper-content">
                                <div className="application-btn">
                                  <img
                                    src="/assets/img/dashboard/icons-alert.svg"
                                    alt="icon"
                                  />
                                  Application check
                                </div>
                                <h5>Get Application Check</h5>
                                <p>
                                  Secure your applications and skip the
                                  paperwork with an application check.
                                </p>
                                <button
                                  className="ui button finish-app"
                                  style={{ marginTop: "37px" }}
                                  onClick={() => this.applicationFee()}
                                >
                                  Proceed to pay
                                </button>
                              </div>
                            ) : (
                              <div className="wrapper-content">
                                <div className="application-btn-success">
                                  <Icon disabled name="shield" />
                                  Application check
                                </div>
                                <h5>
                                  Application Check Active for{" "}
                                  {moment(
                                    this.state.BGdateArray[0].node.submitted
                                  ).diff(
                                    moment().subtract(31, "days"),
                                    "days"
                                  )}{" "}
                                  Days
                                </h5>
                                <p>
                                  Secure your applications and skip the
                                  paperwork with an application check.
                                </p>
                                <a
                                  className="ui button finish-app"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://dev.leasera.com/about-us"
                                >
                                  Learn More
                                </a>
                              </div>
                            )}
                          </Grid.Column>
                        </>
                      )}
                    {/* {this.state.tourCache.length !== 0 && (
                      <Grid.Column className="right__content">
                        <Slider {...settings}>
                          {Array.isArray(this.state.tourCache) &&
                            this.state.tourCache.length > 0 &&
                            this.state.tourCache.map((item) => {
                              const address =
                                item.node.primaryLocation.addresses &&
                                item.node.primaryLocation.addresses[0];
                              const constructAddress =
                                address.streetOne +
                                " " +
                                address.city +
                                " " +
                                address.state +
                                " " +
                                address.country +
                                " " +
                                address.zip;
                              const newHttp = prependHttp(
                                item.node.connectionInfo,
                                { https: false }
                              );
                              const newT = moment(
                                item.node.date + " " + item.node.time,
                                "DD/MM/YYYY HH:mm A"
                              );
                              const secT = moment.utc(newT._i);
                              return (
                                <div className="wrapper-content d-flex flex-column justify-content-between">
                                  <div>
                                    <div className="application-btn-success">
                                      <Icon disabled name="calendar" />
                                      Tours
                                    </div>
                                    <p className="mb-1 mt-2">
                                      {secT
                                        .local()
                                        .format("DD/MM/YYYY hh:mm A")}
                                    </p>
                                    <h6 className="mb-1 mt-2">
                                      {item.node.primaryLocation.name}
                                    </h6>
                                    <p className="mb-1 mt-2">
                                      {constructAddress.replaceAll(
                                        "undefined",
                                        " "
                                      )}
                                    </p>
                                    {item.node.connectionInfo !== "" && (
                                      <p style={{ marginBottom: "0px" }}>
                                        <a
                                          href={newHttp}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Virtual Tour
                                        </a>
                                      </p>
                                    )}
                                  </div>
                                  <a
                                    className="ui button finish-app btn-gradient"
                                    href="/#/Schedule"
                                  >
                                    View Schedule
                                  </a>
                                </div>
                              );
                            })}
                        </Slider>
                      </Grid.Column>
                    )} */}
                  </Grid>
                </div>

                {leases && (
                  <>
                    <h4 className="px-3 px-md-0 mb-3 mt-0">Your properties</h4>
                    <div className="dashboard-col-wrap">
                      <Accordion styled className="dashboard-accordion">
                        {leases && leases.length > 0 ? (
                          leases.map((lease, index) => {
                            const address =
                              lease &&
                              lease.node &&
                              lease.node.location &&
                              JSON.parse(lease.node.location.addresses);
                            const photos = parsePhotos(
                              get(lease, "node.location.photos", [])
                            );
                            /* eslint-disable */
                            const walkScore =
                              lease &&
                              lease.node &&
                              lease.node.location &&
                              JSON.parse(lease.node.location.walkScore);
                            const unitphotos = parsePhotos(
                              get(lease, "node.unit.photos", [])
                            );
                            /* eslint-enable */
                            return (
                              <div
                                className="dashboard-accordion-wrapper"
                                id={activeIndex === index ? "active" : ""}
                              >
                                {activeIndex === index && (
                                  <Button
                                    onClick={() =>
                                      this.handleClick(index, true)
                                    }
                                    className="btn btn-primary close-btn"
                                  >
                                    Close
                                  </Button>
                                )}
                                <Accordion.Title
                                  active={activeIndex === index}
                                  className={activeIndex === index && "active"}
                                  index={index}
                                  onClick={() => this.handleClick(index)}
                                >
                                  <div className="header-title">
                                    <span className="home-icon-page d-none d-md-block">
                                      {this.photoCheck(photos, unitphotos)}
                                    </span>
                                    <div className="single-img">
                                      {this.photoCheckPrimary(
                                        photos,
                                        unitphotos
                                      )}
                                      <div className="buttons-wrap">
                                        <span className="price-tag positive">
                                          {lease.node.status &&
                                            lease.node.status === "move_in" &&
                                            "Leased"}
                                          {lease.node.status &&
                                            lease.node.status === "signed" &&
                                            "Signed"}
                                          {lease.node.status &&
                                            lease.node.status === "created" &&
                                            "Created"}
                                          {lease.node.status &&
                                            lease.node.status ===
                                              "in_progress" &&
                                            "In Progress"}
                                          {lease.node.current &&
                                            lease.node.current === true &&
                                            "Signed"}
                                          {lease.node.status &&
                                            lease.node.status === "renewal" &&
                                            "Under Renewal"}
                                          {lease.node.status &&
                                            lease.node.status === "renewed" &&
                                            "Renewed"}
                                        </span>
                                        <div className="month-price">
                                          {lease.node.amount
                                            ? lease.node.amount + "/month"
                                            : "Non-Network"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="header-title-wrap">
                                      <div className="dashboard-accordion-head">
                                        <div className="d-flex mb-2 mb-md-0">
                                          <span className="home-icon-page d-md-none">
                                            {this.photoCheck(
                                              photos,
                                              unitphotos
                                            )}
                                          </span>
                                          <div>
                                            {lease &&
                                              lease.node &&
                                              lease.node.location &&
                                              lease.node.location.name}{" "}
                                            &nbsp;
                                            {lease.node.moveInChecklist ? (
                                              ""
                                            ) : (
                                              <MoveInChecklist lease={lease} />
                                            )}
                                            <p className="property-address">
                                              #
                                              {lease.node.current === true &&
                                                lease.node.googlePlacesInfo &&
                                                lease.node.googlePlacesInfo
                                                  .unit}
                                              {lease.node.unit &&
                                                lease.node.unit.number}{" "}
                                              {(address
                                                ? address[0].streetOne +
                                                  " " +
                                                  (address.streetTwo
                                                    ? address[0].streetTwo
                                                    : "") +
                                                  " " +
                                                  address[0].city
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  address[0].city.slice(1) +
                                                  " " +
                                                  address[0].state +
                                                  " " +
                                                  address[0].zip
                                                : lease.node.street +
                                                  " " +
                                                  lease.node.city +
                                                  " " +
                                                  lease.node.state +
                                                  " " +
                                                  lease.node.postcode
                                              ).replaceAll(
                                                "undefined",
                                                " "
                                              )}{" "}
                                              {/* <span className="rental-tag">Vacation Rental</span> */}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="property-address-details">
                                          <p>
                                            {lease.node.endDate &&
                                              "Residence currently ends " +
                                                moment(
                                                  lease.node.endDate
                                                ).format("ll")}{" "}
                                            {lease.node.current === true &&
                                              lease.node.googlePlacesInfo &&
                                              lease.node.googlePlacesInfo
                                                .date !== "Invalid date" &&
                                              "Residence currently ends " +
                                                lease.node.googlePlacesInfo
                                                  .date}
                                          </p>
                                          <span className="seprator"></span>
                                          {lease.node.current === true && (
                                            <>
                                              {/* eslint-disable */}
                                              <a
                                                onClick={this.open}
                                                className="book-another-link"
                                              >
                                                Remove Non-Network Property
                                              </a>
                                              {/* eslint-enable */}
                                              <div>
                                                <Confirm
                                                  className="semanticModal"
                                                  size="mini"
                                                  header="Are you sure?"
                                                  content="Once you delete this process cannot be undone. To get this location back please add it through Explore."
                                                  open={this.state.open}
                                                  onCancel={this.close}
                                                  onClick={() =>
                                                    this.removeNonLease(
                                                      lease.node
                                                    )
                                                  }
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="dashboard-accordion-price-area">
                                        <div className="d-flex align-items-center justify-content-end">
                                          {leaseGuarantee.length === 0 &&
                                            lease.node.lgState === null &&
                                            lease.node.skipGuarantee &&
                                            lease.node.lgExpiryTime !== null &&
                                            moment(lease.node.lgExpiryTime) &&
                                            "hours remaining"}
                                          <div className="property-size d-flex align-items-center">
                                            <div className="item">
                                              <span className="show-property-info">
                                                {lease.node.current === true &&
                                                  lease.node.googlePlacesInfo &&
                                                  lease.node.googlePlacesInfo
                                                    .bedrooms &&
                                                  lease.node.googlePlacesInfo
                                                    .bedrooms}
                                                {get(
                                                  lease.node,
                                                  "unit.bedrooms"
                                                )}{" "}
                                                Bed
                                              </span>
                                            </div>
                                            <span className="seprator"></span>
                                            <div className="item">
                                              <span className="show-property-info">
                                                {lease.node.current === true &&
                                                  lease.node.googlePlacesInfo &&
                                                  lease.node.googlePlacesInfo
                                                    .bathrooms &&
                                                  lease.node.googlePlacesInfo
                                                    .bathrooms}
                                                {get(
                                                  lease.node,
                                                  "unit.bathrooms"
                                                )}{" "}
                                                Bath
                                              </span>
                                            </div>
                                            <span className="seprator"></span>
                                            <div className="item">
                                              <span className="show-property-info">
                                                {lease.node.current === true &&
                                                  lease.node.googlePlacesInfo &&
                                                  lease.node.googlePlacesInfo
                                                    .feet &&
                                                  lease.node.googlePlacesInfo
                                                    .feet}
                                                {get(lease.node, "unit.sqft")}
                                                sq.ft
                                              </span>
                                            </div>
                                          </div>
                                          {(lease.node.status === "renewal" ||
                                            lease.node.status ===
                                              "in_progress") && (
                                            <>
                                              <span className="seprator"></span>
                                              <div className="item">
                                                <span
                                                  className="show-property-info ui view-app"
                                                  onClick={() =>
                                                    this.navigateToRenewLease(
                                                      lease
                                                    )
                                                  }
                                                >
                                                  Review Lease
                                                </span>
                                              </div>
                                            </>
                                          )}
                                          {lease.node.documentUrl && (
                                            <>
                                              <span className="seprator"></span>
                                              <div className="item">
                                                <span className="show-property-info">
                                                  <a
                                                    href={
                                                      lease.node.documentUrl
                                                    }
                                                    target="_blank"
                                                  >
                                                    View Lease
                                                  </a>
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Title>
                                <Accordion.Content
                                  active={activeIndex === index}
                                >
                                  <div
                                    className="services-wrapper"
                                    onClick={() => this.offerClick()}
                                  >
                                    {this.state.offers &&
                                      this.state.offers
                                        .slice(0, 4)
                                        .map((offer) => {
                                          return (
                                            <div className="services-wrap-col">
                                              <div
                                                onClick={() =>
                                                  window.open(
                                                    offer.node.link,
                                                    "_blank"
                                                  )
                                                }
                                                style={{
                                                  background: `${offer.node.colorCode}`,
                                                }}
                                                className="services-col"
                                              >
                                                <div className="icon-wrapper">
                                                  <Image
                                                    // floated='right'
                                                    size="massive"
                                                    src={offer.node.logo}
                                                  />
                                                  {/* <img src={offer.node.logo} alt="icon" /> */}
                                                </div>
                                                <div className="services-details">
                                                  <h2>{offer.node.title}</h2>
                                                  <p>{offer.node.desc}</p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                  </div>
                                  <h4>Service Requests</h4>
                                  <div className="avail-services">
                                    <h5 className="other-services">
                                      Leasera Service(s)
                                    </h5>
                                    {leaseGuarantee &&
                                      leaseGuarantee.length > 0 &&
                                      lease.node.lgState === null &&
                                      !lease.node.skipGuarantee && (
                                        <div className="services-card">
                                          <div className="left_wrap">
                                            <h6>Lease Guarantee</h6>
                                            <p>
                                              <strong>Status:</strong>{" "}
                                              <em style={{ color: "red" }}>
                                                Action Required
                                              </em>
                                              .
                                            </p>
                                            <p>
                                              A lease guarantee is an official
                                              agreement signed by the your
                                              manager and yourself in order to
                                              protect both parties.
                                            </p>
                                          </div>
                                          <div className="right_wrap">
                                            <div className="price-tag-wrap">
                                              <span className="price-txt">
                                                $15/Month
                                              </span>
                                            </div>
                                            <LeaseGuaranteeModal
                                              leaseGuarantee={leaseGuarantee}
                                              lease={lease}
                                              setOption={
                                                this.setSelectedLGOption
                                              }
                                              setSkipLGOption={
                                                this.setSkipLGOption
                                              }
                                              checkedItems={checkedItems}
                                            />{" "}
                                          </div>
                                        </div>
                                      )}
                                    {lease.lgInfo && (
                                      <div className="services-card">
                                        <div className="left_wrap">
                                          <h6>
                                            Lease Guarantee{" "}
                                            <Label size="mini" color="green">
                                              {lease.lgInfo.status}
                                            </Label>
                                          </h6>
                                          <p>
                                            <strong>Status:</strong>{" "}
                                            <em style={{ color: "green" }}>
                                              Completed
                                            </em>
                                            .
                                          </p>
                                          <p>
                                            Policy:{" "}
                                            {lease.lgInfo.contract_number}
                                          </p>
                                        </div>
                                        <div className="right_wrap">
                                          <div className="price-tag-wrap">
                                            Expires:{" "}
                                            {moment(
                                              lease.lgInfo.end_date_format
                                            ).format("MM/DD/YYYY")}{" "}
                                            <span className="price-txt">
                                              Coverage: ${lease.lgInfo.coverage}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!lease.node.onBoardScript && (
                                      <>
                                        {!lease.node.current &&
                                        lease.node.insurancePolicy &&
                                        !lease.node.insurancePolicy.status &&
                                        lease.node.insurancePolicy
                                          .insurance_policy === undefined ? (
                                          <div className="services-card">
                                            <div className="left_wrap">
                                              <h6>
                                                Renter's Insurance: Insurance
                                              </h6>
                                              <p>
                                                <strong>Status:</strong>{" "}
                                                <em style={{ color: "red" }}>
                                                  Action Required
                                                </em>
                                                .
                                              </p>
                                              <p>
                                                A property owner should be able
                                                to fully focus on their
                                                business. The unintended
                                                consequences of an uninsured
                                                resident causing catastrophic
                                                damage is a stark reality.
                                                Insurance protects property
                                                owners from accidental
                                                resident-caused damage, saving
                                                them millions of dollars.
                                              </p>
                                            </div>
                                            <div className="right_wrap">
                                              <RllModal
                                                leaseId={lease.node.id}
                                                endDate={lease.node.endDate}
                                                getLeases={this.getLeases}
                                                user={this.props.user}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          !lease.node.current &&
                                          lease.node.insurancePolicy && (
                                            <div className="services-card">
                                              <div className="left_wrap">
                                                <>
                                                  <h6>
                                                    Renter's Insurance:
                                                    {"Insurance"}
                                                  </h6>
                                                  <p>
                                                    <strong>Status:</strong>{" "}
                                                    <em
                                                      style={{ color: "green" }}
                                                    >
                                                      Completed
                                                    </em>
                                                    .
                                                  </p>
                                                  <p>
                                                    {lease.node.insurancePolicy
                                                      .status === true
                                                      ? "A property owner should be able to fully focus on their business. The unintended consequences of an uninsured resident causing catastrophic damage is a stark reality. Insurance protects property owners from accidental resident-caused damage, saving them millions of dollars."
                                                      : lease.node
                                                          .insurancePolicy
                                                          .insurance_policy &&
                                                        `Insurer: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).insurer
                                                        }. Policy Name: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).policyName
                                                        }. Policy Number: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).policyNumber
                                                        }. Effective Date: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).effectiveDate
                                                        }. Expiration Date: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).expirationDate
                                                        }. Cancellation Date: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).cancellationDate
                                                        }. "Reinstatement Date: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).reinstatementDate
                                                        }. Primary Insured: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).primaryInsured
                                                        }. Additionally Insured: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).additionallyInsured
                                                        }. Liability Amount: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).liabilityAmount
                                                        }. Email: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).email
                                                        }. Phone: ${
                                                          JSON.parse(
                                                            lease.node
                                                              .insurancePolicy
                                                              .insurance_policy
                                                          ).phone
                                                        }`}
                                                  </p>
                                                </>
                                              </div>
                                              <div className="right_wrap">
                                                {/* <button className="buy-now">Insurance Confirmed</button> */}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <h5 className="other-services">
                                    Other Service(s)
                                  </h5>
                                  <div className="avail-services">
                                    {this.state.offers &&
                                      this.state.offers.length > 4 && (
                                        <>
                                          {this.state.offers
                                            .slice(4, this.state.offers.length)
                                            .map((offer) => {
                                              return (
                                                <div
                                                  className="other-services-card"
                                                  onClick={() =>
                                                    this.offerClick()
                                                  }
                                                >
                                                  <div className="left_wrap">
                                                    <h6>
                                                      <Image
                                                        floated="left"
                                                        size="mini"
                                                        src={offer.node.logo}
                                                      />
                                                      {offer.node.title}
                                                    </h6>
                                                    <br></br>
                                                    <p>{offer.node.desc}</p>
                                                  </div>
                                                  <div className="right_wrap">
                                                    {/* <span className="price-txt">{offer.node.points}</span> */}
                                                    <button
                                                      onClick={() =>
                                                        window.open(
                                                          offer.node.link,
                                                          "_blank"
                                                        )
                                                      }
                                                      type="button"
                                                      className="view-history"
                                                    >
                                                      Take me there!
                                                    </button>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </>
                                      )}
                                  </div>
                                </Accordion.Content>
                              </div>
                            );
                          })
                        ) : (
                          <div className="dashboard-empty-wrap">
                            <img
                              src="assets/img/dashboard/empty-properties.svg"
                              alt="event"
                            />
                            <div className="empty-title">
                              Empty? Seems like it’s your first time.
                            </div>
                            <div className="empty-subtitle">
                              Don’t worry, we will help you to fill this
                              emptiness.
                            </div>
                            <Link
                              to="/explore"
                              className="btn btn-gradient mt-4"
                            >
                              Explore
                            </Link>
                          </div>
                        )}

                        {inactiveLeases.length > 0 && (
                          <div className="subhead-accordian">
                            Rental History
                          </div>
                        )}
                        {inactiveLeases.length > 0 &&
                          inactiveLeases.map((lease, index) => {
                            const photos = parsePhotos(
                              get(lease, "node.location.photos", [])
                            );
                            const address =
                              lease &&
                              lease.node &&
                              JSON.parse(lease.node.location.addresses);
                            return (
                              <div className="dashboard-accordion-wrapper rental-history">
                                <Accordion.Title
                                  active={activeIndex === index + 100}
                                  index={index + 100}
                                >
                                  <div className="header-title">
                                    <span className="home-icon-page">
                                      {photos.length >= 1 ? (
                                        <img
                                          style={{
                                            objectFit: "cover",
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          src={photos[0]}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="assets/img/dashboard/property-image.png"
                                          alt=""
                                        />
                                      )}
                                    </span>
                                    <div className="dashboard-accordion-head">
                                      <p className="property-address">
                                        {/* {lease.node.location.name} */}#
                                        {lease.node.unit.number}{" "}
                                        {(
                                          address &&
                                          address[0].streetOne +
                                            " " +
                                            (address.streetTwo
                                              ? address[0].streetTwo
                                              : "") +
                                            " " +
                                            address[0].city
                                              .charAt(0)
                                              .toUpperCase() +
                                            address[0].city.slice(1) +
                                            " " +
                                            address[0].state +
                                            " " +
                                            address[0].zip
                                        ).replaceAll("undefined", " ")}{" "}
                                      </p>
                                      <div className="property-address-details">
                                        <p>
                                          {" "}
                                          Residence currently ends{" "}
                                          {lease.node.endDate &&
                                            moment(lease.node.endDate).format(
                                              "ll"
                                            )}{" "}
                                        </p>
                                        <span className="seprator"></span>
                                      </div>
                                    </div>
                                    <div className="dashboard-accordion-price-area">
                                      <div className="d-flex align-items-center justify-content-end">
                                        <div className="property-size d-flex align-items-center">
                                          <div className="item">
                                            <span className="show-property-info">
                                              {get(lease.node, "unit.bedrooms")}{" "}
                                              Bed
                                            </span>
                                          </div>
                                          <span className="seprator"></span>
                                          <div className="item">
                                            <span className="show-property-info">
                                              {get(
                                                lease.node,
                                                "unit.bathrooms"
                                              )}{" "}
                                              Bath
                                            </span>
                                          </div>
                                          <span className="seprator"></span>
                                          <div className="item">
                                            <span className="show-property-info">
                                              {get(lease.node, "unit.sqft")}{" "}
                                              sq.ft
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Title>
                              </div>
                            );
                          })}
                      </Accordion>
                    </div>
                  </>
                )}
              </div>
            </div>
            {selectedMapLoc && (
              <MapItModal
                modalOpen={mapStatus}
                data={selectedMapLoc}
                openMapModal={this.openMapModal}
              />
            )}
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
            />
          </>
        )}
      </>
    );
  }
}

export default withApollo(Dashboard);
