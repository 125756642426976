import React from "react";
import {
  Grid,
  Dropdown,
  Button,
  Card,
  Header,
  Modal,
  Image,
  Label,
  List,
} from "semantic-ui-react";
import { Loader } from "../../components/Loader/Loader";
import "./Explore.scss";
import set from "lodash/set";
import { SEARCH_PROP, APPLICATION_RENTER } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { propertiesGoogleGql } from "../../store/person/properties";
import { ToastContainer, toast } from "react-toastify";
import AddRentalModal from "../../components/Modals/AddRentalModal";
import { createApplication } from "../../store/person/applications";
import mixpanel from "mixpanel-browser";
import ApplicationForm from "../Applications/ApplicationForm";
import OuterPlaceAutoSearch from "../../components/Explore/OuterPlaceAutoSearch";

const unitClient = getClient(SEARCH_PROP);
const toLocClient = getClient(APPLICATION_RENTER);
export function AddRental(props) {
  const [loc, setLocation] = React.useState(false);
  const [locContact, setContactLocation] = React.useState(false);
  const [possibleLocations, setPossibleLocations] = React.useState(false);
  const [theContact, setSocials] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [confirmopen, setConfirm] = React.useState(false);
  const [requiredFields, setrequiredFields] = React.useState(null);
  const { values, setFieldValue } = props;

  const switchLoc = (location) => {
    setConfirm(true);
    setContactLocation(location);
  };
  const checkValue = () => {
    setPossibleLocations([]);
    setLoader(true);
    const { googlePlaceId, location } = loc;

    if (location && googlePlaceId && requiredFields.length === 0) {
      mixpanel.track("Renter Explore Action", {
        sub: "Add Location Search Click",
      });
      try {
        unitClient
          .query({
            query: propertiesGoogleGql,
            variables: {
              filter: {
                googlePlaceId,
                location,
              },
            },
          })
          .then((res) => {
            setLoader(false);
            setPossibleLocations(res.data.location.edges);
            setSearched(true);
          })
          .catch((error) => {
            setSearched(true);
            setLoader(false);
          });
      } catch (e) {
        setSearched(true);
        setLoader(false);
      }
    }
  };
  const contactLocation = (application) => {
    application = {
      ...application.application,
      currentResident: true,
      locationId: locContact.node.id,
    };
    const app = (application = {
      application,
    });
    mixpanel.track("Renter Application Action", {
      sub: "Current Resident Submit",
    });
    try {
      toLocClient
        .mutate({
          mutation: createApplication,
          variables: {
            input: app,
          },
        })
        .then((res) => {
          setConfirm(false);
          success();
          setTimeout(function () {
            window.location.href = "www.dev.renter.leasera.com";
          }, 2000);
        })
        .catch((error) => {
          fail();
        });
    } catch (e) {}
  };
  const success = () => {
    toast.success("Request Sent!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const fail = () => {
    toast.error("This unit does not exist for the selected location!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onChangeField = (key, value) => {
    setrequiredFields("");
    setPossibleLocations([]);
    setSearched(false);
    let errorString = "";
    const placeTypes = ["street_number", "route", "postal_code"];
    var fieldData = [];
    if (key === "googlePlaceId") {
      set(values, key, value.googlePlaceId);
      set(values, "location", value.location);
      setLocation(value);
    } else {
      set(values, key, value);
      setFieldValue(key, value);
    }

    value.address.forEach((val) => {
      fieldData.push(val.types[0]);
    });

    placeTypes.forEach((val) => {
      if (!fieldData.find((value) => value === val)) {
        switch (val) {
          case "street_number":
            return (errorString = errorString + "Street Number, ");
          case "route":
            return (errorString = errorString + "Route, ");
          case "postal_code":
            return (errorString = errorString + "Postal Code, ");
          default:
            break;
        }
      }
    });
    setrequiredFields(errorString);
    console.log(errorString);
  };

  return (
    <>
      <div className="homepage">
        <div className="home-banner renter-banner">
          <Grid divided="vertically half-image-section renter-image-section">
            <Grid.Row columns={1}>
              <Grid.Column>
                <h5>Add Location </h5>
                <p>Please add your location in the system </p>
                {requiredFields && (
                  <p style={{ color: "red" }}>
                    Required Fields({" "}
                    {requiredFields.substr(0, requiredFields.length - 2)})
                  </p>
                )}
                <OuterPlaceAutoSearch
                  id="searchbox"
                  onChange={(newValue) => {
                    onChangeField(`googlePlaceId`, newValue);
                  }}
                  className="form-control"
                  googlePlaceId={values && values.googlePlaceId}
                  checkValue={checkValue}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid divided="vertically half-image-section renter-point-section">
            {searched && (
              <>
                <Grid.Row className="explore" columns={1}>
                  <h5 style={{ marginLeft: "15px" }}>
                    {possibleLocations.length !== 0
                      ? "Heres what we found"
                      : "It does not look like we have any properties within your search radius."}{" "}
                  </h5>
                  <Grid.Column
                    style={{ maxHeight: "550px", overflowY: "scroll" }}
                  >
                    {loader && (
                      <Loader
                        text
                        className="container-loader"
                        size="massive"
                        active
                        inline="centered"
                      />
                    )}
                    {possibleLocations &&
                      possibleLocations.map((location) => {
                        const contact =
                          location.node.contact &&
                          JSON.parse(JSON.parse(location.node.contact));
                        return (
                          <Card style={{ marginBottom: "10px" }} fluid>
                            <Card.Content>
                              <Card.Header>
                                <div className="card-header">
                                  <div className="left-head">
                                    <h5>{location.node.name}</h5>
                                    <h6>
                                      {location.node.addresses &&
                                        location.node.addresses[0].streetOne +
                                          ", "}
                                      {location.node.addresses &&
                                        location.node.addresses[0].city + ", "}
                                      {location.node.addresses &&
                                        location.node.addresses[0].state + ", "}
                                      {location.node.addresses &&
                                        location.node.addresses[0].zip}
                                    </h6>
                                  </div>
                                  <div className="right-head">
                                    <Button
                                      className={`action-btn assigned-btn`}
                                      style={{ padding: "0px" }}
                                      onClick={() => switchLoc(location)}
                                    >
                                      Add
                                    </Button>
                                    {contact &&
                                      (contact.phone ||
                                        contact.email ||
                                        Object.keys(contact.socials).length !==
                                          0) && (
                                        <Modal
                                          className="semanticModal"
                                          onClose={() => setOpen(false)}
                                          onOpen={() => setOpen(true)}
                                          open={open}
                                          size="mini"
                                          trigger={
                                            <Button
                                              className={`action-btn assigned-btn`}
                                              style={{
                                                padding: "0px",
                                                background: "grey",
                                              }}
                                              button
                                              text="Contact"
                                              onClick={() =>
                                                setSocials(contact)
                                              }
                                            >
                                              Contact
                                            </Button>
                                          }
                                        >
                                          <Modal.Header>Contact</Modal.Header>

                                          <Grid celled padded columns="2">
                                            <Grid.Column>
                                              <div>
                                                <Image
                                                  avatar
                                                  src={"/assets/img/phone.svg"}
                                                />
                                                <br></br>
                                                <span>
                                                  {" "}
                                                  {theContact.phone}{" "}
                                                </span>
                                              </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <div>
                                                <Image
                                                  avatar
                                                  src={"/assets/img/email.svg"}
                                                />
                                                <br></br>
                                                <span
                                                  style={{
                                                    overflowWrap: "break-word",
                                                  }}
                                                >
                                                  {" "}
                                                  {theContact.email}{" "}
                                                </span>
                                              </div>
                                            </Grid.Column>
                                            {theContact.socials &&
                                              theContact.socials.facebook && (
                                                <Grid.Column>
                                                  <div>
                                                    <a
                                                      target="_blank"
                                                      image
                                                      href={`${
                                                        theContact.socials.facebook.includes(
                                                          "https://"
                                                        )
                                                          ? theContact.socials
                                                              .facebook
                                                          : `https://${theContact.socials.facebook}`
                                                      }`}
                                                    >
                                                      <Image
                                                        avatar
                                                        src={
                                                          "/assets/img/facebook.svg"
                                                        }
                                                      />
                                                      <br></br>
                                                      <span className="contact-hover">
                                                        {" "}
                                                        Facebook{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </Grid.Column>
                                              )}

                                            {theContact.socials &&
                                              theContact.socials.instagram && (
                                                <Grid.Column>
                                                  <div>
                                                    <a
                                                      href={`${
                                                        theContact.socials.instagram.includes(
                                                          "https://"
                                                        )
                                                          ? theContact.socials
                                                              .instagram
                                                          : `https://${theContact.socials.instagram}`
                                                      }`}
                                                      target="_blank"
                                                    >
                                                      <Image
                                                        avatar
                                                        src={
                                                          "/assets/img/instagram.svg"
                                                        }
                                                      />
                                                      <br></br>
                                                      <span className="contact-hover">
                                                        {" "}
                                                        Instagram{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </Grid.Column>
                                              )}

                                            {theContact.socials &&
                                              theContact.socials.yelp && (
                                                <Grid.Column>
                                                  <div>
                                                    <a
                                                      href={`${
                                                        theContact.socials.yelp.includes(
                                                          "https://"
                                                        )
                                                          ? theContact.socials
                                                              .yelp
                                                          : `https://${theContact.socials.yelp}`
                                                      }`}
                                                      target="_blank"
                                                    >
                                                      <Image
                                                        avatar
                                                        src={
                                                          "/assets/img/yelp.svg"
                                                        }
                                                      />
                                                      <br></br>
                                                      <span className="contact-hover">
                                                        {" "}
                                                        Yelp{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </Grid.Column>
                                              )}

                                            {theContact.socials &&
                                              theContact.socials.linkedIn && (
                                                <Grid.Column>
                                                  <div>
                                                    <a
                                                      href={`${
                                                        theContact.socials.linkedIn.includes(
                                                          "https://"
                                                        )
                                                          ? theContact.socials
                                                              .linkedIn
                                                          : `https://${theContact.socials.linkedIn}`
                                                      }`}
                                                      target="_blank"
                                                    >
                                                      <Image
                                                        avatar
                                                        src={
                                                          "/assets/img/linkedin.png"
                                                        }
                                                      />
                                                      <br></br>
                                                      <span className="contact-hover">
                                                        {" "}
                                                        LinkedIn{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </Grid.Column>
                                              )}

                                            {theContact.socials &&
                                              theContact.socials.twitter && (
                                                <Grid.Column>
                                                  <div>
                                                    <a
                                                      href={`${
                                                        theContact.socials.twitter.includes(
                                                          "https://"
                                                        )
                                                          ? theContact.socials
                                                              .twitter
                                                          : `https://${theContact.socials.twitter}`
                                                      }`}
                                                      target="_blank"
                                                    >
                                                      <Image
                                                        avatar
                                                        src={
                                                          "/assets/img/twitter.svg"
                                                        }
                                                      />
                                                      <br></br>
                                                      <span className="contact-hover">
                                                        {" "}
                                                        Twitter{" "}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </Grid.Column>
                                              )}
                                          </Grid>
                                          <Modal.Actions>
                                            <Button
                                              color="black"
                                              onClick={() => setOpen(false)}
                                            >
                                              Close
                                            </Button>
                                          </Modal.Actions>
                                        </Modal>
                                      )}
                                    {/* <span className={`action-btn assigned-btn `}>{socialCheck(contact)}</span> */}
                                  </div>
                                </div>
                              </Card.Header>
                            </Card.Content>
                          </Card>
                        );
                      })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered columns={1} className="rental">
                  <Grid.Column>
                    {props.user &&
                      props.user.addresses &&
                      props.user.addresses.filter((address) => address.current)
                        .length !== 3 && (
                        <AddRentalModal
                          success={success}
                          fail={fail}
                          location={loc}
                          fullAddress={true}
                        />
                      )}
                    {props.user && !props.user.addresses && (
                      <AddRentalModal
                        success={success}
                        fail={fail}
                        location={loc}
                        fullAddress={true}
                      />
                    )}
                    {props.user &&
                      props.user.addresses &&
                      props.user.addresses.filter((address) => address.current)
                        .length === 3 && (
                        <p textalign="center">
                          It does not look like we have any properties within
                          your search radius. We only allow up to three
                          Non-Network properties per user. If you wish to add
                          this location on your dashboard for all the benefits
                          Leasera has to offer then please remove an existing
                          location.
                        </p>
                      )}
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
          <Modal
            closeIcon
            className="semanticModal"
            size="large"
            // onCancel={() => setConfirm(false)} no duplicate props. If broken swap with prop below.
            onCancel={() => setConfirm(true)}
            open={confirmopen}
            // trigger={<Button>Show Modal</Button>}
          >
            <Modal.Header>{`Request access to ${
              locContact.node && locContact.node.name
            }?`}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Header>
                  We just need a bit more information about your current lease.
                </Header>
                <ApplicationForm
                  contactLocation={contactLocation}
                  location={locContact.node && locContact.node.id}
                  user={props.user}
                  addRental
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setConfirm(false)}>Close</Button>
            </Modal.Actions>
          </Modal>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
      </div>
    </>
  );
}

export default AddRental;
