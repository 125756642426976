import React, { Component } from "react";
import { BankAccountModal } from "../../components/Modals/BankAccountModal";
import { CreditCardModal } from "../../components/Modals/CreditCardModal";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import get from "lodash/get";
import moment from "moment";
import { PaymentSuccess } from "../../components/Success/PaymentSuccess";
import mixpanel from "mixpanel-browser";
import {
  Tab,
  Checkbox,
  Popup,
  Dimmer,
  Progress,
  Dropdown,
  Segment,
  Modal,
  Divider,
  Card,
  List,
  Button,
  Grid,
} from "semantic-ui-react";
// Style
import "./Payments.scss";
import questionIcon from "../../assets/img/question-icon.svg";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { applicationsGql } from "../../store/person/applications";
import { TA_SUBMIT, WEPAY_URL } from "../../utils/constants";
import { getEmitter } from "../../utils";
import ReCAPTCHA from "react-google-recaptcha";
import {
  personMethodsGql,
  submitTAGql,
  savedInfoPaymentMutationGql,
  personPaymentActionACHGql,
  personPaymentActionCCGql,
} from "../../store/person/payment";

const paymentsClient = getClient(WEPAY_URL);
const TAClient = getClient(TA_SUBMIT);
const eventEmit = getEmitter();
class CardPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardModalOpen: false,
      pointsAdded: this.props.thePoints,
      concessionsAdded: this.props.theConcessions,
      method: "",
      loading: false,
      showState: false,
      captcha: false,
      sucFail: false,
      loadingTime: 10,
      application: "",
      ssn: "",
      modal: false,
      ssnre: "",
      selectedBill: this.props.selectedBill,
      achOptions:
        this.props.paymentOptions &&
        this.props.paymentOptions.filter(
          (card) => card.node.type === "ACH"
        ),
      cardOptions:
        this.props.paymentOptions &&
        this.props.paymentOptions.filter(
          (card) => card.node.type === "CreditCard"
        ),
      achS: true,
      showAmount: this.props.selectedBill && this.props.selectedBill.amountPayable,
      achP: true,
      cardS: true,
      cardP: true,
      isShowing: false,
      optionSelected: null,
      setSelectedAutoPay: null,
      process: false
    };
    this.fileInputRef = React.createRef();
    this._reCaptchaRef = React.createRef();
  }
  state = { activeIndex: 1 };
  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
    this.setState({ method: "" });
    this.setState({ loading: false });
    this.setState({ ssn: "" });
    var ele = document.getElementsByName("radio-group");
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;
  };
  setMethod = (value) => {
    this.setState({ method: value });
  };
  handleChange = (value) => {
    this.setState({ captcha: value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };
  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
  };

  handleProcess = (x) => this.setState({ process: x });

  achScreen = () => this.setState(({ achS }) => ({ achS: !achS }));
  achPayment = () => this.setState(({ achP }) => ({ achP: !achP }));
  cardScreen = () => this.setState(({ cardS }) => ({ cardS: !cardS }));
  cardPayment = () => {
    this.setState(({ cardP }) => ({ cardP: !cardP }));
  };

  componentDidMount() {
    if (window.location.href.indexOf("applicationFee") > -1) {
      mixpanel.track("Renter Payment Action", {
        sub: "Payment Screen Render Application Fee",
      });
      this.setState({ ssn: "", loading: false });
      this.getPaymentOptions();
      this.fetchApplications();
    } else {
      mixpanel.track("Renter Payment Action", {
        sub: "Payment Screen Render General Payment",
      });
    }
  }

  autopayCheck = () => {
    this.state.paymentOptions.forEach((payment) => {
      if (payment.node.autopay === true)
        this.setState({ selectedAutopay: payment.node });
    });
  };

  getPaymentOptions = () => {
    paymentsClient
      .query({
        query: personMethodsGql,
      })
      .then((results) => {
        let paymentOptions = results.data.paymentMethods.edges;
        this.setState({
          achOptions: paymentOptions.filter(
            (card) => card.type === "ACH"
          ),
        });
        this.setState({
          cardOptions: paymentOptions.filter(
            (card) => card.type === "CreditCard"
          ),
        });
        this.setState({ paymentOptions: paymentOptions });
        this.autopayCheck();
      })
      .catch((e) => { });
  };

  fetchApplications = () => {
    try {
      this.props.applicationManager
        .query({
          query: applicationsGql,
        })
        .then((response) => {
          const applications = get(response, "data.applications.edges", []);
          applications.filter(
            (ele) =>
              ele.node.status !== "Cancelled" || ele.node.status !== "Denied"
          );
          applications.sort(
            (a, b) =>
              moment(a.node.created).valueOf() -
              moment(b.node.created).valueOf()
          );
          applications.reverse();
          this.setState({ application: applications[0] });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  applicationPay = () => {
    this.setState({ loading: true });
    this.setState({ loadingTime: 20 });
    mixpanel.track("Renter Payment Action", { sub: "Application Fee Submit" });
    paymentsClient
      .mutate({
        mutation: savedInfoPaymentMutationGql,
        variables: {
          paymentMethod:
          {
            type: "manual_reoccuring",
            backgroundCheck: true,
            amount: 49.95,
            reoccuring: {
              key: this.state.method.paymentMethodKey.toString(),
              type:
                this.state.method.paymentMethodType === "ACH"
                  ? "bank"
                  : "credit_card",
            },
          }
        },
      })
      .then((results) => {
        this.setState({ loadingTime: 40 });
        this.submitTA();
      })
      .catch((e) => {
        toastFailMsg(parseGraphQLErrors(e));
        this.setState({ method: "" });
        this.setState({ showState: true })
        this.setState({ sucFail: false })
      });
  };
  formatSocialSecurity(val) {
    val = val.replace(/\D/g, "");
    val = val.replace(/^(\d{3})/, "$1-");
    val = val.replace(/-(\d{2})/, "-$1-");
    val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    return val;
  }

  submitTA = () => {
    this.setState({ loadingTime: 60 });
    mixpanel.track("Renter Payment Action", { sub: "TA Function Fires" });
    try {
      TAClient.mutate({
        mutation: submitTAGql,
        variables: {
          tenantAlert: {
            applicationId: this.state.application.node.heartlandToken,
            verificationNum: this.state.ssn.replace(/-/g, "").slice(0, 9),
          },
        },
      })
        .then((res) => {
          this.setState({ loadingTime: 80 });
          this.setState({ showState: true })
          if (res.data.tenantAlertRequest.response === "OK") {
            this.setState({ loadingTime: 100 });
            toastSuccessMsg("Application Check Successful!");
            eventEmit.emit("points");
            this.setState({ sucFail: true })
          }
          if (res.data.tenantAlertRequest.response !== "OK") {
            this.setState({ loading: false });
            toastFailMsg(
              parseGraphQLErrors(res.data.tenantAlertRequest.response)
            );
            this.setState({ sucFail: false })
          }
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error));
          this.setState({ showState: true })
          this.setState({ loading: false });
          this.setState({ sucFail: false })
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleBack = () => {
    if (this.props.setToPay) {
      this.props.setToPay("");
      setTimeout(() => {
        this.props.flipBack();
      }, 100);
    } else {
      this.props.history.push({
        pathname: "/dashboard",
      });
    }
  };


  toggleIsShowing = () => this.setState({ isShowing: !this.state.isShowing });

  render() {
    const { activeIndex, selectedBill } = this.state;

    const panes = [
      {
        menuItem: "Bank Accounts",
        render: () => (

          <Tab.Pane>
            <div className="account-list">
              {this.state.achOptions &&
                this.state.achOptions.map((option) => {
                  return (
                    <div className="list">
                      <div className="bank-name-txt">
                        <div className="card-detail">
                          <img
                            src="/assets/img/icons-bank.svg"
                            alt="bank icon"
                          ></img>
                          <h6> {option.node.name}</h6>
                          {/* <span>{option.node.heartlandToken}</span> */}
                        </div>
                      </div>
                      <div className="select-btn">
                        <div>
                          <input
                            type="radio"
                            id={option.node.heartlandToken}
                            onClick={() => this.setMethod(option)}
                            name="radio-group"
                          />
                          <label for={option.node.heartlandToken}></label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {window.location.href.indexOf("applicationFee") > -1 && (
                <div className="list w-50">
                  <div className="bank-name-txt">
                    <div className="card-detail">
                      <span>
                        SSN <span style={{ color: "red" }}>&#x2a;</span>
                      </span>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({ ssn: event.target.value });
                        }}
                        name="ssn"
                        id="ssn"
                        value={this.formatSocialSecurity(this.state.ssn)}
                        className="form-control"
                      ></input>
                    </div>
                    <div className="card-detail" style={{ marginLeft: "20px" }}>
                      <span>
                        Re-Enter SSN{" "}
                        <span style={{ color: "red" }}>&#x2a;</span>
                      </span>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({ ssnre: event.target.value });
                        }}
                        value={this.formatSocialSecurity(this.state.ssnre)}
                        name="ssnre"
                        id="ssnre"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="select-btn">
                    <div>
                      <Popup
                        content="In order for us to properly send an Application Check we need to verify some information using your SSN."
                        trigger={<img src={questionIcon} alt="question icon" />}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="tnc-row">
                <div className="tnc-col">
                  <Checkbox
                    defaultChecked
                    onChange={() => this.achPayment()}
                    label={
                      <label>
                        I agree to the payment{" "}
                        <a
                          href="https://dev.leasera.com/Terms"
                          className="contact-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                </div>
                <div className="tnc-col">
                  <Checkbox
                    onChange={() => this.achScreen()}
                    defaultChecked
                    label={
                      <label>
                        I agree to the screening{" "}
                        <a
                          href="https://dev.leasera.com/Terms"
                          className="contact-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="card-footer-btn">
              <>
                {
                  this.props.chosenMethod &&
                  <Card>
                    <Card.Content>
                      <Card.Header textAlign="center">Review</Card.Header>
                      <Card.Description>
                        <Grid>
                          <Grid.Row columns="2">
                            <Grid.Column textAlign="right">
                              Amount
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                              ${this.props.chosenMethod[0].totalAmount - this.props.chosenMethod[0].convenienceAmount}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns="2">
                            <Grid.Column textAlign="right">
                              Fee ({`${this.props.chosenMethod[0].chargeType == "fixed" ? `$${this.props.chosenMethod[0].amount}` : `${this.props.chosenMethod[0].amount}%`}`})
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                              + ${Number(this.props.chosenMethod[0].convenienceAmount).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                          {
                            this.state.concessionsAdded !== 0 &&
                            <Grid.Row columns="2">
                              <Grid.Column textAlign="right">
                                Concessions
                              </Grid.Column>
                              <Grid.Column textAlign="right">
                                - ${parseFloat(this.state.concessionsAdded).toFixed(2)}
                              </Grid.Column>
                            </Grid.Row>
                          }
                          <Divider />
                          <Grid.Row columns={2}>
                            <Grid.Column textAlign="right">
                              Total
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                              ${parseFloat(this.props.chosenMethod[0].totalAmount - this.state.concessionsAdded).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                }
                <button
                  type="button"
                  disabled={
                    this.state.achP == false ||
                    this.state.achS == false ||
                    !this.state.method ||
                    (window.location.href.indexOf("applicationFee") > -1 &&
                      this.state.ssn.length < 11) ||
                    this.state.loading ||
                    (window.location.href.indexOf("applicationFee") > -1 &&
                      this.state.ssn !== this.state.ssnre)
                  }
                  onClick={() => this.setState({ modal: true, value: null })}
                  className="btn btn-primary btn-proceed"
                >
                  {window.location.href.indexOf("applicationFee") > -1
                    ? "Proceed to pay $49.95"
                    : `Proceed to pay $ ${parseFloat(this.props.chosenMethod[0].totalAmount).toFixed(2) - this.state.concessionsAdded
                    }`}
                </button>
                {/* eslint-enable */}

              </>
            </div>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Credit Cards",
        render: () => (
          <Tab.Pane>
            <div className="account-list">
              {this.state.cardOptions &&
                this.state.cardOptions.map((option) => {
                  const cardType = option.node.cardType.split("*")[0];
                  return (
                    <div className="list">
                      <div className="bank-name-txt">
                        <div className="card-image">
                          {option.node.type === null ? (
                            <img
                              src="/assets/img/icons-bank.svg"
                              alt="bank icon"
                            ></img>
                          ) : (
                            <>
                              {cardType.trim() === "visa" && (
                                <img
                                  src="/assets/img/logo-visa.svg"
                                  alt="bank icon"
                                ></img>
                              )}
                              {cardType.trim() === "discover" && (
                                <img
                                  src="/assets/img/logo-discover.svg"
                                  alt="bank icon"
                                ></img>
                              )}
                              {cardType.trim() === "mastercard" && (
                                <img
                                  src="/assets/img/master-logo.svg"
                                  alt="bank icon"
                                ></img>
                              )}
                              {cardType.trim() === "amex" && (
                                <img
                                  src="/assets/img/logo-amex.svg"
                                  alt="bank icon"
                                ></img>
                              )}
                              {/* {cardType == 'J' &&<img src="/assets/img/logo-discover.svg" alt="bank icon"></img>} */}
                            </>
                          )}
                        </div>
                        <div className="card-detail">
                          <h6>{option.node.type}</h6>
                          <span>{option.node.name}</span>
                        </div>
                      </div>
                      <div className="select-btn">
                        <div>
                          <input
                            type="radio"
                            id={option.node.heartlandToken}
                            name="radio-group"
                            onClick={() => this.setMethod(option)}
                          />
                          <label for={option.node.heartlandToken}></label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {window.location.href.indexOf("applicationFee") > -1 && (
                <div className="list w-50">
                  <div className="bank-name-txt">
                    <div className="card-detail">
                      <span>
                        SSN <span style={{ color: "red" }}>&#x2a;</span>
                      </span>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({ ssn: event.target.value });
                        }}
                        value={this.formatSocialSecurity(this.state.ssn)}
                        name="ssn"
                        id="ssn"
                        className="form-control"
                      ></input>
                    </div>
                    <div className="card-detail" style={{ marginLeft: "20px" }}>
                      <span>
                        Re-Enter SSN{" "}
                        <span style={{ color: "red" }}>&#x2a;</span>
                      </span>
                      <input
                        type="text"
                        onChange={(event) => {
                          this.setState({ ssnre: event.target.value });
                        }}
                        value={this.formatSocialSecurity(this.state.ssnre)}
                        name="ssnre"
                        id="ssnre"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="select-btn">
                    <div>
                      <Popup
                        content="In order for us to properly send an Application Check we need to verify some information using your SSN."
                        trigger={<img src={questionIcon} alt="question icon" />}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="tnc-row">
                <div className="tnc-col">
                  <Checkbox
                    defaultChecked
                    onChange={() => this.cardPayment()}
                    label={
                      <label>
                        I agree to the payment{" "}
                        <a
                          href="https://dev.leasera.com/Terms"
                          className="contact-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                </div>
                <div className="tnc-col">
                  <Checkbox
                    defaultChecked
                    onChange={() => this.cardScreen()}
                    label={
                      <label>
                        I agree to the screening{" "}
                        <a
                          href="https://dev.leasera.com/Terms"
                          className="contact-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                </div>

              </div>
            </div>
            <div className="card-footer-btn">
              <>
                {
                  this.props.chosenMethod &&
                  <Card>
                    <Card.Content>
                      <Card.Header textAlign="center">Review</Card.Header>
                      <Card.Description>
                        <Grid columns="2">
                          <Grid.Row>
                            <Grid.Column textAlign="right">
                              Amount
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                              ${Number(this.props.chosenMethod[1].totalAmount - this.props.chosenMethod[1].convenienceAmount).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column textAlign="right">
                              Fee ({`${this.props.chosenMethod[1].chargeType == "fixed" ? `$${this.props.chosenMethod[1].amount}` : `${this.props.chosenMethod[1].amount}%`}`})
                            </Grid.Column>
                            {
                              this.props.chosenMethod[1].convenienceAmount &&
                              <Grid.Column textAlign="right">
                                + ${parseFloat(this.props.chosenMethod[1].convenienceAmount).toFixed(2)}
                              </Grid.Column>
                            }
                          </Grid.Row>
                          {
                            this.state.concessionsAdded !== 0 &&
                            <Grid.Row>
                              <Grid.Column textAlign="right">
                                Concessions
                              </Grid.Column>
                              <Grid.Column textAlign="right">
                                - ${parseFloat(this.state.concessionsAdded).toFixed(2)}
                              </Grid.Column>
                            </Grid.Row>
                          }
                          <Divider />
                          <Grid.Row columns={2}>
                            <Grid.Column textAlign="right">
                              Total
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                              ${parseFloat(this.props.chosenMethod[1].totalAmount - this.state.concessionsAdded).toFixed(2)}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                }
                <button
                  type="button"
                  disabled={
                    this.state.cardP === false ||
                    this.state.cardS === false ||
                    !this.state.method ||
                    (window.location.href.indexOf("applicationFee") > -1 &&
                      this.state.ssn.length < 11) ||
                    this.state.loading ||
                    (window.location.href.indexOf("applicationFee") > -1 &&
                      this.state.ssn !== this.state.ssnre)
                  }
                  onClick={() => this.setState({ modal: true, value: null })}
                  className="btn btn-primary btn-proceed"
                >
                  {" "}
                  {window.location.href.indexOf("applicationFee") > -1
                    ? "Proceed to pay $49.95"
                    : `Proceed to pay $ ${parseFloat(this.props.chosenMethod[1].totalAmount).toFixed(2) - this.state.concessionsAdded
                    }`}
                </button>
                {/* eslint-enable */}

              </>
            </div>
          </Tab.Pane>
        ),
      },
    ];

    const languageOptions = [
      {
        key: "Bank Account",
        text: "Bank Account",
        value: "Bank Account",
        className: "account-img",
      },
      {
        key: "Credit Card",
        text: "Credit Card",
        value: "Credit Card",
        className: "credit-img",
      },
    ];

    const handleOnChange = (e) => {
      this.setState({ isShowing: true });
      this.setState({ optionSelected: e.target.textContent });
    };

    const handleAddBankAccount = async (event) => {
      event.preventDefault();
      const { name, account, routing, address1, city, zip } = event.target;
      const getAch = document.getElementById("type");
      const getAcc = document.getElementById("accType");
      const getState = document.getElementById("state");
      mixpanel.track("Renter Payment Action", { sub: "Add Bank Account" });
      // Request single user token from heartland
      try {
        paymentsClient
          .mutate({
            mutation: personPaymentActionACHGql,
            variables: {
              paymentMethod: {
                type: "bank",
                bank: {
                  achType: getAcc && getAcc.value.toString(),
                  accountType: getAch && getAch.value.toString(),
                  routingNumber: routing && routing.value.toString(),
                  nameOnAccount: name && name.value.toString(),
                  accountNumber: account && account.value.toString(),
                  addressLine1: address1 && address1.value.toString(),
                  city: city && city.value.toString(),
                  stateProvince: getState && getState.value.toString(),
                  zipPostalCode: zip && zip.value.toString(),
                },
              },
            },
          })
          .then((res) => {
            this.getPaymentOptions();
            toastSuccessMsg("Bank added successfully.");
            this.setState({ isShowing: false });
            setTimeout(() => {
              this.forceUpdate();
            }, 1000);
          })
          .catch((error) => {
            toastFailMsg(parseGraphQLErrors(error));
          });
      } catch (e) {
        toastFailMsg(parseGraphQLErrors(e));
        console.log(e);
      }
    };

    const handleAddCreditCardSubmit = (event) => {
      event.preventDefault();
      const { name, number, zip, month, year } = event.target;
      this.handleProcess(true);

      // const { name, number, zip, expYear, expMonth, cvv } = this.state;
      mixpanel.track("Renter Payment Action", { sub: "Add CC" });
      const n = number.value.toString().replace(/-/g, "");
      try {
        paymentsClient
          .mutate({
            mutation: personPaymentActionCCGql,
            variables: {
              paymentMethod: {
                type: "credit_card",
                creditCard: {
                  nameOnAccount: name.value.toString(),
                  card: {
                    number: n,
                    expMon: parseInt(month.value),
                    expYear: parseInt(year.value),
                  },
                  zipPostalCode: zip.value.toString(),
                },
              },
            },
          })
          .then((res) => {
            toastSuccessMsg("Card added successfully.");
            this.getPaymentOptions();
            this.handleProcess(false);
            this.setState({ isShowing: false });
            setTimeout(() => {
              this.forceUpdate();
            }, 1000);
          })
          .catch((error) => {
            this.handleProcess(false);
            toastFailMsg(parseGraphQLErrors(error));
          });
      } catch (e) {
        toastFailMsg(parseGraphQLErrors(e));
        this.handleProcess(false);
        console.log(e);
      }
    };

    return (
      <div>
        {
          !this.state.showState ?
            <div className="card-payment-wrap">
              <div className="card-payment-header">
                <span className="back-arrow">
                  <img
                    src="/assets/img/right-arrow.svg"
                    onClick={() => this.props.setPayInvoice(1)}
                    alt="arrow"
                  ></img>
                </span>
                <h5>Payment Methods{this.state.appId}</h5>
                <Dropdown
                  button
                  floating
                  labeled
                  style={{ float: "right" }}
                  options={languageOptions}
                  text="Add Payment Method"
                  className="btn btn-primary"
                  onMouseDown={(e) => handleOnChange(e)}
                />
              </div>
              {this.state.isShowing &&
                this.state.optionSelected === "Bank Account" && (
                  <BankAccountModal
                    handleAddBankAccount={handleAddBankAccount}
                    showModal={this.state.isShowing}
                    toggleIsShowing={this.toggleIsShowing}
                  />
                )}
              {this.state.isShowing &&
                this.state.optionSelected === "Credit Card" && (
                  <CreditCardModal
                    handleAddCreditCardSubmit={handleAddCreditCardSubmit}
                    process={this.state.process}
                    showModal={this.state.isShowing}
                    toggleIsShowing={this.toggleIsShowing}
                  />
                )}
              {window.location.href.indexOf("applicationFee") > -1 && (
                <div className="card-payment-subheader">
                  <div className="subheader-label">Application Fee</div>
                  <div className="subheader-value">$49.95</div>
                </div>
              )}
              <div className="card-payment-content">
                {this.state.loading ? (
                  <Dimmer active inverted>
                    <Progress percent={this.state.loadingTime} indicating />
                    Processing Request
                  </Dimmer>
                ) : (
                  <Tab
                    panes={panes}
                    activeIndex={activeIndex}
                    selectedBill={selectedBill}
                    onTabChange={this.handleTabChange}
                  />
                )}
                <>
                  <Modal
                    className="semanticModal semanticModal-Captcha"
                    size="mini"
                    onClose={() => this.setState({ modal: false })}
                    onOpen={() => this.setState({ modal: true })}
                    open={this.state.modal}
                  >
                    <Modal.Header>Please Confirm Payment</Modal.Header>
                    <Modal.Content>
                      <Modal.Description>
                        {this.state.modal && (
                          <ReCAPTCHA
                            // style={{ display: "inline-block" }}
                            className="captchaIframe"
                            theme="dark"
                            ref={this._reCaptchaRef}
                            sitekey="6LdCHrIaAAAAAOHJVlGIVZOTKpQnqTrCY9ivk6PG"
                            onChange={this.handleChange}
                            asyncScriptOnLoad={this.asyncScriptOnLoad}
                          />
                        )}
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        loading={this.state.loading}
                        style={{ maxHeight: "36px", maxWidth: '151.6px' }}
                        disabled={!this.state.captcha || this.state.loading}
                        onClick={() =>
                          window.location.href.indexOf("applicationFee") > -1
                            ? this.applicationPay()
                            : this.props.payUsingSaved(
                              this.state.method,
                              this.state.selectedBill,
                              this.state.pointsAdded,
                              this.state.concessionsAdded,
                              this.state.captcha
                            )
                        }
                        positive
                      >{this.state.loading ? "Loading" : "Confirm Payment"}</Button>
                    </Modal.Actions>
                  </Modal>
                </>
              </div>
            </div>
            :
            <PaymentSuccess sucFail={this.state.sucFail} background={true} />
        }
      </div>
    );
  }
}

export default CardPayment;
