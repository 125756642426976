import React, { useState, useEffect, useRef } from "react";
import { withApollo } from "react-apollo";
import get from "lodash/get";
import { maintenanceGql, updateMaintenance } from "../../store/person/maintenance";
import { PageLoader } from "../../components/Loader/PageLoader";
import axios from "axios";
import { leasesGql } from "../../store/person/leases";
import { qpDatatables } from "../../utils/misc";
import "./Maintenance.scss";
import "./dist/Maintenance.css";
import MaintenanceModal from "../../components/Modals/MaintenanceModal";
import { MAINTENANCE_URL } from "../../utils/constants";
import "../../assets/fomantic/dist/semantic.css";
import { parseGraphQLErrors, toastFailMsg, toastSuccessMsg } from "../../utils/common";
import {
  Header,
  Button,
  Card,
  Dropdown,
  Form,
  TextArea,
  Grid,
  Modal,
  Image,
} from "semantic-ui-react";
import moment from "moment";
import CancelModal from "../../components/Modals/CancelModal";
import getAuthToken from "../../store/auth/authUtility";
import FileType from "file-type/browser";
import FileViewer from "react-file-viewer";
import MaintenanceContactModal from "../../components/Modals/MaintenanceContactModal";
import mixpanel from "mixpanel-browser";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import LeaLoader from "../../components/Loader/LeaLoader";

function useOutsideAlerter(ref, fn) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function Maintenance(props) {
  /* eslint-disable */
  const [state, setState] = useState({
    isError: false,
    activeLeases: null,
    phoneNumber: "111-222-3333",
    email: "contactus@support.to",
    cancel: false,
  });
  /* eslint-enable */
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [unitNum, setUnitNum] = useState(props.unitNum);
  const [primaryId, setPrimaryId] = useState(props.primaryId);
  const [modalData, setModalData] = useState(null);
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setDropdownOpen);

  // New
  const maintenanceClient = getClient(MAINTENANCE_URL);

  const toModal = (request) => {
    mixpanel.track("Renter Maintenance Action", {
      sub: "Maintenance Request View Detail",
    });
    if (request !== null) {
      setModalData(request);
      view(request.node);
      setOpen(true);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    qpDatatables();
    getActiveLeases();
    getMaintenanceRequests();
  }, []);

  useEffect(() => {
    const nodeData = primaryId ? primaryId : "";
    if (nodeData !== primaryId) {
      setPrimaryId(nodeData, () => {
        getActiveLeases();
        getMaintenanceRequests();
      });
    }
  }, [state]);
  /* eslint-enable */

  const getActiveLeases = async () => {
    try {
      await props.leasesClient
        .query({
          query: leasesGql,
          variables: {
            role: "renter",
            status: "Active",
          },
        })
        .then((response) => {
          const id = get(response.data, "leases.edges");
          const unit = get(response.data, "leases.edges");
          setPrimaryId(id);
          setUnitNum(unit);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const view = (path) => {
    let url = new URL(MAINTENANCE_URL);
    setFile("");
    setType("");
    url.searchParams.append(["path"], path.fileUrls);
    url.searchParams.append("location", path.location);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "JSON", // important
    }).then(
      async (response) => {
        console.log(response);
        setFile(response.data.result);
        /* eslint-enable */
      },
      () => {}
    );
  };

  const getMaintenanceRequests = () => {
    setLoading(true);
    const roleType = "renter";
    const statusType = [
      "Submitted",
      "Processing",
      "Scheduled",
      "Entered",
      "Completed",
      "Canceled",
      "Assigned",
      "Approval Needed",
      "Approved",
    ];
    try {
      props.maintenanceClient
        .query({
          query: maintenanceGql,
          variables: {
            role: roleType,
            status: statusType,
            // location: props.primaryId
          },
        })
        .then((response) => {
          setLoading(false);
          setRequests(get(response, "data.maintenanceRequest.edges", null));
          setFilteredRequests(
            get(response, "data.maintenanceRequest.edges", null)
          );
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          // setMessage(`Oops! There was a problem fetching maintenance requests: ${parseGraphQLErrors(error)}`, false)
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };


  const setViewable = (photo) => {
    setOpenImage(true);
    setType(photo);
  };

  const handleSearch = (e) => {
    const searchResults = requests.filter((i) => {
      // Filter results by doing case insensitive match on name here
      return i.node.requestedByDesc
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    if (searchResults.length > 0 && e.target.value !== "") {
      setFilteredRequests(searchResults);
    } else {
      setFilteredRequests(requests);
    }
  };

  const handleCancelModal = (x) => setCancelModalOpen(x);

  const handleCancelSubmit = (e, modalData) => {
    console.log(modalData)
    setIsSubmitting(true);
    mixpanel.track("Renter Maintenance Action", {
      sub: "Cancel Maintenance Request Submit",
    });
    try {
      maintenanceClient
        .mutate({
          mutation: updateMaintenance,
          variables: {
            input: {
              maintenance: {
                role: "renter",
                id: modalData.id,
                location: modalData.location,
                status: "Canceled",
                reason: e.target.reason.value,
              },
            },
          },
        })
        .then((res) => {
          setIsSubmitting(false);
          setOpen(false);
          setCancelModalOpen(false);
          toastSuccessMsg("Request Canceled Successfully");
          getMaintenanceRequests();
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error));
          console.log(error);
        });
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const handleDropdownClick = (i) => {
    if (dropdownOpen === i) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(i);
    }
  }

  return (
    loading ?
      <PageLoader />
    :
      <>
        <div className="maintenance-wrapper">
          <div className="header-rw-responsive">
            <div className="left-top-head">
              <Header textAlign="left">Maintenance</Header>
              <p>Here’s the list of your maintenance requests</p>
            </div>
            <div className="right-top-head-responsive">
              <>
                <input
                  className="input-res"
                  placeholder="Search..."
                  type="text"
                  onChange={(e) => handleSearch(e)}
                />
              </>
              <>
                <Button.Group floated="right">
                  <MaintenanceModal
                    unit={unitNum}
                    props={props}
                    primaryId={primaryId}
                    leasesClient={props.leasesClient}
                    getMaintenanceRequests={() => getMaintenanceRequests()}
                  />
                </Button.Group>
              </>
            </div>
          </div>
          <div className="maintenance-listing">
            {requests &&
              filteredRequests.map((request, i) => {
                return (
                  <>
                    <Card>
                      <Card.Content
                        className="pointer"
                        data-toggle="modal"
                        data-target="#descmodal"
                        onClick={() => toModal(request)}
                      >
                        <Card.Header>
                          <div className="card-header">
                            <div className="left-head">
                              <h5>
                                {request.node.locationInfo &&
                                  request.node.locationInfo.name}{" "}
                                #{request.node.unit}
                              </h5>
                              <h6>{moment(request.node.created).format("ll")}</h6>
                            </div>
                            <div className="right-head right-maintenance">
                              {/* eslint-disable */}
                              <span
                                style={{ marginRight: "15px" }}
                                className={`action-btn assigned-btn ${
                                  request.node.status == "Assigned" ||
                                  "Entered" ||
                                  ("Scheduled" && "assigned-btn")
                                } 
                              ${request.node.status === "Canceled" && "cancelled-btn"}
                              ${request.node.status === "Completed" && "resolved-btn"}`}
                              >
                                {request.node.status}
                              </span>
                              {/* eslint-enable */}
                              <span
                                data-placement="top"
                                data-dismiss="modal"
                                data-target="#descmodal"
                              >
                                <div ref={wrapperRef} style={{position: 'relative', right: '-84px', bottom: '27px'}}>
                                  <Dropdown
                                    style={{ padding: "0px" }}
                                    simple
                                    button
                                    text=""
                                    icon="ellipsis vertical"
                                    open={dropdownOpen === i}
                                    onClick={()=> handleDropdownClick(i)}
                                  >
                                    <Dropdown.Menu>
                                      {/* {request.node.canceledDate === null && (
                                        <CancelModal
                                          cancelSubmit={handleCancelSubmit}
                                          cancelModalOpen={cancelModalOpen}
                                          handleCancelModal={handleCancelModal}
                                          modalData={request.node}
                                          isSubmitting={isSubmitting}
                                        />
                                      )} */}
                                      {/* <Dropdown.Item className="customer-txt" text='Customer Service' /> */}
                                      <Dropdown.Item
                                        className="contact-txt"
                                        onClick={() => {
                                          setModalIsOpen(!modalIsOpen);
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "13px",
                                            color: "#5e6267",
                                          }}
                                        >
                                          Contact
                                        </span>
                                        <MaintenanceContactModal
                                          isOpen={modalIsOpen}
                                          phoneNumber={state.phoneNumber}
                                          email={state.email}
                                        />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </span>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Description>
                          {request.node.requestedByDesc}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </>
                );
              })}
            <Modal
              basic
              class="semanticModal"
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              size="tiny"
            >
              <div class="desc-detail-modal" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <img src="/assets/img/close-modal.svg" alt="close"></img>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="data-rw">
                      <div className="list">
                        <h5>Maintenance Request</h5>
                        <h6>
                          {modalData &&
                            moment(modalData.node.created).format("ll")}
                        </h6>
                      </div>
                      <div className="list">
                        {/* eslint-disable */}
                        <span
                          className={`action-btn assigned-btn ${
                            (modalData && modalData.node.status === "Assigned") ||
                            "Entered" ||
                            ("Scheduled" && "assigned-btn")
                          } 
                          ${modalData && modalData.node.status === "Canceled" && "cancelled-btn"}
                          ${modalData && modalData.node.status === "Completed" && "resolved-btn"}`}
                        >
                          {modalData && modalData.node.status}
                        </span>
                        {/* eslint-enable */}
                      </div>
                    </div>
                    <div className="data-rw">
                      <div className="list">
                        <label>Submitted by</label>
                        <span>
                          {modalData &&
                            modalData.node.requestedBy &&
                            modalData.node.requestedBy.firstName}
                          {modalData &&
                            modalData.node.requestedBy &&
                            modalData.node.requestedBy.lastName}
                        </span>
                      </div>
                      <div className="list">
                        <label>Category</label>
                        <span style={{ textTransform: "capitalize" }}>
                          {modalData &&
                            modalData.node.category &&
                            modalData.node.category.replace("_", " ")}
                        </span>
                      </div>
                    </div>
                    <div className="data-rw">
                      <div className="list">
                        <label>Request ID</label>
                        <span>{modalData && modalData.node.id}</span>
                      </div>
                    </div>
                    <div className="data-rw">
                      <div className="list">
                        <label>Unit</label>
                        <span>{modalData && modalData.node.unit}</span>
                      </div>
                    </div>
                    <div className="data-rw">
                      <div className="list">
                        <label>Description</label>
                        <span>{modalData && modalData.node.requestedByDesc}</span>
                      </div>
                    </div>
                    {modalData &&
                      modalData.node.petType !== "" &&
                      modalData.node.petType !== null && (
                        <div className="data-rw">
                          <div className="list">
                            <label>Pet Information</label>
                            <span>{modalData && modalData.node.petType}</span>
                          </div>
                        </div>
                      )}
                    {modalData &&
                      modalData.node.fileUrls &&
                      modalData.node.fileUrls.length !== 0 && (
                        <div className="data-rw">
                          <div className="list">
                            <label>Photos</label>
                            <Grid
                              style={{ paddingTop: "20px", paddingBottom: "5px" }}
                            >
                              <Image.Group size="tiny">
                                {file &&
                                  file.map((photo) => {
                                    return (
                                      <Image
                                        src={`data:${photo}`}
                                        size="tiny"
                                        onClick={() => setViewable(photo)}
                                      />
                                    );
                                  })}
                              </Image.Group>
                            </Grid>
                          </div>
                        </div>
                      )}
                    {/* <a className="view-link">view invoice</a> */}
                  </div>
                  {modalData && (
                    <div
                      class=""
                      data-dismiss="modal"
                      aria-label="Close"
                      data-toggle="modal"
                      data-target="#descmodal"
                    >
                      {modalData.node.canceledDate === null && (
                        <>
                          <Form onSubmit={(e) => handleCancelSubmit(e, modalData.node)} style={{ height: "100px" }}>
                            <TextArea
                              style={{ height: "100px" }}
                              placeholder="Please let us know why you are canceling this request."
                              name="reason"
                            />
                            <Button
                              style={{ background: "#4C158A", height: '35.99px', width: '86px', color: "#F2FCFB", position: "absolute", bottom: '-46px', right: '6px', zIndex: '2'}}
                              type="submit"
                              content="Submit"
                            >
                              {isSubmitting ?
                                <LeaLoader logoStyle={{height: '40px', position: 'relative', bottom: '12px'}}/>
                              :
                                'Cancel'
                              }
                            </Button>
                          </Form>
                          <Modal.Actions>
                            <Button onClick={() => setOpen(false)} style={{ background: "#343c49", color: "#F2FCFB", margin: '10px 0 10px 154px'}}>Close</Button>
                          </Modal.Actions>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Modal
                basic
                className="semanticModal"
                onClose={() => setOpenImage(false)}
                open={openImage}
                size="small"
              >
                <Modal.Header>Image</Modal.Header>
                <Modal.Content className="imageviewer">
                  {type && <Image src={`data:${type}`} size="large" />}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    icon="check"
                    content="Close"
                    onClick={() => setOpenImage(false)}
                  />
                </Modal.Actions>
              </Modal>
            </Modal>
          </div>
        </div>
      </>
  );
}
export default withApollo(Maintenance);
