import React, { Component } from "react";
import { CarouselTrips } from "../../components/Quill/Carousel";
import "./Trips.scss";
import PropertyCard from "../../components/Property/PropertyCard";
import { PropertyModal } from "../../components/Modals/PropertyModal";
import { FAKE_PROPERTY, COMING_SOON, BOOKING_TRIPS } from "../../utils/constants";
// import { Loader } from "../../components/Loader/Loader";
import { TripsEmpty } from "../../components/EmptyCards/TripsEmpty";
import { qpTooltipPopover } from "../../utils/misc";
import "../../assets/fomantic/dist/semantic.css";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { Carousel } from "react-responsive-carousel";
import CurrentHomeCard from "../../components/Homes/CurrentHomeCard";
import PropertyDetail from "../../components/Property/PropertyDetail";
import {
  BookingTrips
} from "../../store/person/leases";
import "../../assets/fomantic/dist/semantic.css";
const BOOKING = getClient(BOOKING_TRIPS);
class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShortLease: true,
      pastShortLeases: true,
      filter: {
        currentShortLease: true,
        pastShortLeases: true,
      },
      modalProperty: FAKE_PROPERTY,
      trips: [],
      primaryTrip: false
    };
  }

  componentDidMount() {
    this.fetchTrips()
    qpTooltipPopover();
    // this.getLeases()
  }

  selectedTrip = (e) => {
    let filter = {};
    switch (e.target.value) {
      case "all":
        filter = {
          currentShortLease: true,
          pastShortLeases: true,
        };
        break;
      case "past":
        filter = {
          pastShortLeases: true,
        };
        break;
      case "current":
        filter = { currentShortLease: true };
        break;
      default:
        filter = {
          currentShortLease: true,
          pastShortLeases: true,
        };
        break;
    }
    this.setState({
      filter: filter,
    });
  };

  fetchTrips = () => {
    try {
      BOOKING
        .query({
          query: BookingTrips,
          variables: {
            filter: {
              renterEmail: this.props.user.email
            }
          }
        })
        .then((res) => {
          if (res.data) {
            const trip = res.data.reservationList.edges
            // trip.map(val => {
            //   val.node.bookingDetails = JSON.parse(val.node.bookingDetails)
            //   return val
            // })
            this.setState({ trips: trip })
            this.setState({ primaryTrip: trip[0] })
            console.log(trip[0])
          }
        }).catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  setModalProperty = (modalProperty) => this.setState({ modalProperty });

  render() {
    const { modalProperty, trips, primaryTrip } = this.state;
    return (
      <main className="main-content renter-trips-wrap" role="main">
        <div className="row">
          <div className="col-md-12">
            <h2>Trips</h2>
            <h5>Here’s the summary of your trips</h5>
            <Carousel >
              <div className="carousel-item carousel-extend active">
                <div
                  className="row"
                  data-toggle="tooltip"
                  data-placement="right"
                >
                  {trips &&
                    trips.map((photo) => {
                      const tripSet = photo.node
                      return (
                        <>
                          <div className="col-md-4" onClick={() => this.setState({ primaryTrip: photo })}>
                            <div className="img-tall-overflow">
                              <div className="offer-img-overlay">
                                <p className="offer-title">{tripSet.hotelDetails.result&&tripSet.hotelDetails.result[0].hotel_data.name}</p>
                                <p className="offer-price points ">${tripSet.bookingDetails.block.min_price && tripSet.bookingDetails.block.min_price.price}/Night</p>
                                <div className="offer-points"><span className="batch-icon batch-icon-star-alt"></span>{tripSet.hotelDetails.result&&tripSet.hotelDetails.result[0].hotel_data.review_score}</div>
                              </div>
                              <hr />
                              <img
                                className="img-fluid"
                                src={tripSet.bookingDetails.photo}
                                alt="Product 1"
                              />
                              <span className="offer-price">$1,200/Night</span>
                              <div className="offers-txt">
                                <h6>Sky High Hayat Villa</h6>
                                <p>
                                  11311 19th Ave SE, everett, Washington DC, 98208, US
                                </p>
                              </div>
                            </div>
                          </div>

                        </>
                      );
                    })}
                </div>
              </div>
            </Carousel>
            {/* <CarouselT trips={trips} /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading heading-md">Upcoming Trips</h3>
          </div>
        </div>
        <div className="row">
          <div
            className="past-trips-grid"
            data-toggle="tooltip"
            data-placement="right"
          >
            {/*using this filter because actual filter below has no data*/}
            {!this.state.primaryTrip ? (
              <div>
                <TripsEmpty />
              </div>
            ) : (
              <CurrentHomeCard primaryTrip={primaryTrip}/>
              // <div className="card property-card">
              //   <div className="card-image-box">
              //     <Carousel >
              //       <div className="carousel-item carousel-extend active">
              //         <div
              //           className="row"
              //           data-toggle="tooltip"
              //           data-placement="right"
              //         >
              //           {primaryTrip &&
              //             primaryTrip.node.bookingDetails.block.photos.map((photo) => {
              //               const tripSet = photo.node
              //               return (
              //                 <>
              //                   <div className="col-md-4">
              //                     <div className="img-tall-overflow">
              //                       <img
              //                         className="card-img-top img-fluid"
              //                         src={photo.url_original
              //                         }
              //                         data-holder-rendered="true"
              //                         alt="Card img"
              //                       />

              //                     </div>
              //                   </div>
              //                 </>
              //               );
              //             })}
              //         </div>
              //       </div>
              //     </Carousel>
              //     <div
              //       className="heart-btn"
              //       data-toggle="tooltip"
              //       data-placement="right"
              //     ></div>
              //     {<div className="active-btn">Active</div>}
              //   </div>
              //   <div className="card-body">
              //     {(
              //       <PropertyDetail
              //       primaryTrip={primaryTrip}
              //       />
              //     )}

              //   </div>
              // </div>

            )}
          </div>
        </div>
      </main>
    );
  }
}

export default Trips;
