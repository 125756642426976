import React, { useState, useEffect } from "react";
import { Header, Grid, Tab, Dropdown, Button, Popup } from "semantic-ui-react";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Table as TableR } from "../../components/Payments/PaymentsData";
import CardPayment from "./CardPayment";
import AutopaySetup from "../../components/Modals/AutopaySetup";
import { PaymentSuccess } from "../../components/Success/PaymentSuccess";
import mixpanel from "mixpanel-browser";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";
import { WEPAY_URL } from "../../utils/constants";
import { getEmitter } from "../../utils";

import {
  currentBalanceGql,
  personMethodsGql,
  personPaymentActionACHGql,
  personPaymentActionCCGql,
  personPaymentDeleteGql,
  personPaymentScheduleGql,
  personPaymentScheduleDeleteGql,
  personScheduleGql,
  savedInfoPaymentMutationGql,
  updatePaymentMethodGql,
} from "../../store/person/payment";
import { leaseBillsGql } from "../../store/person/invoices";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { BankAccountModal } from "../../components/Modals/BankAccountModal";
import { CreditCardModal } from "../../components/Modals/CreditCardModal";
import { PLAIDModal } from "../../components/Modals/PLAIDModal";
import { Link } from "react-router-dom";
import LeaLoader from "../../components/Loader/LeaLoader";
import "./paymentsResponsive.css";
import InvoiceDetail from "./InvoiceDetail";

// Style
import "./Payments.scss";

const paymentsClient = getClient(WEPAY_URL);
const WepayClient = getClient(WEPAY_URL)
const eventEmit = getEmitter();

function Payments(props) {
  // handleShow = handleShow.bind(this);
  // handleClose = handleClose.bind(this);
  const [autopayOpen, setAutopayOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [autoPays, setAutoPays] = useState([]);
  const [bills, setBills] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [sucFail, setSucFail] = useState([]);
  const [infoStore, setInfoStore] = useState({});
  const [selectedBill, setSelectedBill] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedPaymentAuto, setSelectedPaymentAuto] = useState("");
  const [chosenMethod, setChosenMethod] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [payInvoice, setPayInvoice] = useState(1);
  const [leaseBills, setLeaseBills] = useState(null);
  const [process, setProcess] = React.useState(false);
  const [thePoints, setThePoints] = useState(0);
  const [theConcessions, setTheConcessions] = useState(0);
  const toggleIsShowing = (x) => { setIsShowing(x); }
  /* eslint-disable */
  useEffect(() => {
    getUpcomingBills();
    getPaymentOptions();
    getInvoices();
    viewAutopay();
    document.body.addEventListener("click", (e) => {
      if (!e.target.closest("#detailmodal")) {
        setSelectedBill("");
      }
    });
  }, []);
  /* eslint-enable */

  

  const setToPay = (bill) => {
    setPayInvoice(4);
    setSelectedBill(bill);
  };
  const deletePayment = (payment) => {
    setSelectedPayment(payment);
  };
  

  const viewAutopay = (e) => {
    if (e === "skip") {
      setLoading(false);
    }
    WepayClient
      .query({
        query: personScheduleGql,
      })
      .then((results) => {
        setLoading(false);
        setAutoPays(results.data.schedules.edges);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const deletePersonPayment = () => {
    setProcess(true);
    document.getElementById("close").click();
    if (selectedPayment.charAt(0) == "/") {
      mixpanel.track("Renter Payment Action", {
        sub: "Delete Scheduled Payment",
      });
      try {
        WepayClient
          .mutate({
            mutation: personPaymentScheduleDeleteGql,
            variables: {
              schedule: {
                id: selectedPayment.substring(1),
              },
            },
          })
          .then((results) => {
            const newPayments = autoPays.filter((payment) => {
              return payment.node.id !== selectedPayment.substring(1);
            });
            setAutoPays(newPayments);
            setProcess(false);
          })
          .catch((e) => {
            setProcess(false);
            console.log(e);
            toastFailMsg(parseGraphQLErrors(e));
          });
      } catch (e) {
        setProcess(false);
        console.log(e);
        toastFailMsg(parseGraphQLErrors(e));
      }
    } else {
      mixpanel.track("Renter Payment Action", { sub: "Delete Saved Payment" });
      try {
        WepayClient
          .mutate({
            mutation: personPaymentDeleteGql,
            variables: {
              paymentMethod: {
                id: selectedPayment.substring(1),
              },
            },
          })
          .then(() => {
            const newPayments = paymentOptions.filter((payment) => {
              return payment.node.id !== selectedPayment.substring(1);
            });
            setPaymentOptions(newPayments);
            setProcess(false);
          })
          .catch((error) => {
            setProcess(false);
            console.log(error);
            toastFailMsg(parseGraphQLErrors(error));
          });
      } catch (e) {
        setProcess(false);
        console.log(e);
        toastFailMsg(parseGraphQLErrors(e));
      }
    }
  };

  const getInvoices = () => {
    paymentsClient
      .query({
        query: leaseBillsGql,
        variables: {
          refunds: true,
        },
      })
      .then((res) => {
        setBills(
          res.data.invoices.edges.filter(
            (node) => node.node.status !== "created"
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const passAndDismiss = (value) => {
    mixpanel.track("Renter Payment Action", {
      sub: "Payment Screen Proceed to Payment",
    });
    setTimeout(() => {
      setPayInvoice(2);
      if (document.getElementById("close")) {
        dismiss();
      }
    }, 100);
  };
  const flipBack = () => setPayInvoice(1);

  const handleAutopayOpen = (x) => setAutopayOpen(x);

  const dismiss = () => {
    document.getElementById("close").click();
  };
  // modal
  const handleOnChange = (e) => {
    setIsShowing(true);
    setOptionSelected(e.target.textContent);
  };

  const scheduleItem = (event) => {
    event.preventDefault();
    setProcess(true);
    const { lease, method, process, endDate, conc } = event.target;
    const concessions = conc && JSON.parse(conc.value);
    const activeLease = JSON.parse(lease.value);
    const activePayment = JSON.parse(method.value);
    const startDate = document.getElementById("startDate").textContent;
    var mySubString = startDate.replace("/01/", process.value);
    mixpanel.track("Renter Payment Action", {
      sub: "Save New Scheduled Payment",
    });
    try {
      WepayClient
        .mutate({
          mutation: personPaymentScheduleGql,
          variables: {
            schedule: {
              scheduleName: activeLease.location.name,
              paymentMethodType: activePayment.node.type,
              locationId: activeLease.location.id,
              applyConcessions: concessions ? true : false,
              splitConcessionsEqually: concessions ? true : false,
              scheduleIdentifier: activeLease.unitId + "-" + activeLease.id,
              paymentMethodKey: activePayment.node.heartlandToken,
              processingDateInfo: process.value,
              scheduleStatus: "active",
              subtotalAmount: {
                value: activeLease.amount.toString(),
              },
              startDate: mySubString,
              chargeType:"RENT",
              endDate: endDate.value.replace(/\//g, ""),
              frequency: "monthly",
              duration: "end_date",
              scheduleType: "lease",
              refNum: activeLease.id,
            },
          },
        })
        .then((results) => {
          setProcess(false);
          toastSuccessMsg("Autopay successfully scheduled.");
          eventEmit.emit("ScheduleSuccess");
          viewAutopay("skip");
        })
        .catch((e) => {
          toastFailMsg(parseGraphQLErrors(e));
          setAutopayOpen(false);
          setProcess(false);
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  // Add new Credit Card
  const handleAddCreditCardSubmit = (event) => {
    event.preventDefault();
    setProcess(true);
    const { name, number, zip, month, year, cvv } = event.target;
    mixpanel.track("Renter Payment Action", { sub: "Add CC" });
    const n = number.value.toString().replace(/-/g, "");
    try {
      WepayClient
        .mutate({
          mutation: personPaymentActionCCGql,
          variables: {
            paymentMethod: {
              type: "credit_card",
              creditCard: {
                nameOnAccount: name.value.toString(),
                card: {
                  number: n,
                  expMon: parseInt(month.value),
                  expYear: parseInt(year.value),
                  cvv:parseInt(cvv.value)
                },
                zipPostalCode: zip.value.toString(),
              },
            },
          },
        })
        .then((res) => {
          toastSuccessMsg("Card added successfully.");
          getPaymentOptions();
          setProcess(false);
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false);
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false);
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const handleAddBankAccount = async (event) => {
    event.preventDefault();
    setProcess(true);
    const { name, account, routing, address1, city, zip } = event.target;
    const getAch = document.getElementById("type");
    const getAcc = document.getElementById("accType");
    const getState = document.getElementById("state");
    mixpanel.track("Renter Payment Action", { sub: "Add Bank Account" });

    // Request single user token from heartland
    try {
      await WepayClient
        .mutate({
          mutation: personPaymentActionACHGql,
          variables: {
            paymentMethod: {
              type: "bank",
              bank: {
                achType: getAcc && getAcc.value.toString(),
                accountType: getAch && getAch.value.toString(),
                routingNumber: routing && routing.value.toString(),
                nameOnAccount: name && name.value.toString(),
                accountNumber: account && account.value.toString(),
                addressLine1: address1 && address1.value.toString(),
                city: city && city.value.toString(),
                stateProvince: getState && getState.value.toString(),
                zipPostalCode: zip && zip.value.toString(),
              },
            },
          },
        })
        .then((res) => {
          setProcess(false);
          getPaymentOptions();
          toastSuccessMsg("Bank added successfully.");
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false);
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false);
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const handleAddCCPLAID = (event) => {
    try {
      WepayClient
        .mutate({
          mutation: personPaymentActionACHGql,
          variables: {
            paymentMethod: {
              type: "credit_card",
              token: event,
              payoutMethod:false
            },
          },
        })
        .then((res) => {
          setProcess(false);
          getPaymentOptions();
          toastSuccessMsg("Card added successfully.");
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false);
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false);
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const handleAddBankAccountPLAID = (event) => {
    try {
      WepayClient
        .mutate({
          mutation: personPaymentActionACHGql,
          variables: {
            paymentMethod: {
              type: "bank",
              token: event,
              payoutMethod:false
            },
          },
        })
        .then((res) => {
          setProcess(false);
          getPaymentOptions();
          toastSuccessMsg("Bank added successfully.");
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false);
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false);
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const updatePayment = (selectedPayment) => {
    if (selectedPayment.paymentMethodType === "Credit Card") {
      try {
        WepayClient
          .mutate({
            mutation: updatePaymentMethodGql,
            variables: {
              paymentMethod: {
                type: "credit_card",
                id: selectedPayment.node.heartlandToken,
                creditCard: {
                  preferredPayment: !selectedPayment.preferredPayment,
                },
              },
            },
          })
          .then((results) => {
            toastSuccessMsg(
              `You have successfully changed your preferred payment method.`
            );
            getPaymentOptions();
          })
          .catch((e) => {
            toastFailMsg(
              "Oops! An error has occurred, please try again in a few minutes."
            );
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        WepayClient
          .mutate({
            mutation: updatePaymentMethodGql,
            variables: {
              paymentMethod: {
                type: "bank",
                id: selectedPayment.node.id,
                bank: {
                  preferredPayment: !selectedPayment.preferredPayment,
                },
              },
            },
          })
          .then((results) => {
            toastSuccessMsg(
              `You have successfully changed your preferred payment method!`
            );
            getPaymentOptions();
          })
          .catch((e) => {
            toastFailMsg(
              "Oops! An error has occurred, please try again in a few minutes."
            );
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getPaymentOptions = () => {
    WepayClient
      .query({
        query: personMethodsGql,
      })
      .then((results) => {
        var options = results.data.paymentMethods.edges;
        const filteredArr = options.filter(function(obj) {
          return obj.node.status !== "deleted";
        });
        console.log(filteredArr)
        setPaymentOptions(filteredArr);
        // autopayCheck();
      })
      .catch((e) => { });
  };

  const getUpcomingBills = () => {
    setLoading(true);
    paymentsClient
      .query({
        query: currentBalanceGql,
      })
      .then((res) => {
        setLeaseBills(res.data.currentBalance);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const paymentSuccessFail = (value, e) => {
    if (value) {
      setSucFail([true, e]);
    } else {
      setSucFail([false, e]);
    }
    setPayInvoice(3);
  };



  const payUsingSaved = (payment, bill, pointsAdded, conc, captcha) => {
    setLoading(true);
    mixpanel.track("Renter Payment Action", { sub: "General Payment Submit" });
    WepayClient
      .mutate({
        mutation: savedInfoPaymentMutationGql,
        variables: {
          paymentMethod:
          {
            type: "manual_reoccuring",
            invoiceId: bill.Id,
            useLoyaltyPoints: pointsAdded !== 0 ? true : false,
            loyaltyPoints: pointsAdded,
            useConcessions: theConcessions !== 0 ? true : false,
            concessionsAmount: theConcessions,
            amount: parseFloat(infoStore.amount),
            test:false,
            captcha,
            reoccuring: {
              key: payment.node.heartlandToken.toString(),
              type:
                payment.node.type === "ACH"
                  ? "bank"
                  : "credit_card",
            },
          },
        },
      })
      .then((results) => {
        toastSuccessMsg("Payment sent!");
        setLoading(false);
        getInvoices();
        paymentSuccessFail(true);
        setSelectedBill("");
      })
      .catch((e) => {
        setSelectedBill("");
        paymentSuccessFail(false, e);
        toastFailMsg(parseGraphQLErrors(e));
        setLoading(false);
      });
  };

  const { user } = props;
  const languageOptions = [
    // {
    //   key: "Bank Account",
    //   text: "Bank Account",
    //   value: "Bank Account",
    //   className: "account-img",
    // },
    {
      key: "Credit Card",
      text: "Credit Card",
      value: "Credit Card",
      className: "credit-img",
    }, 
    {
      key: "PLAID",
      text: "PLAID",
      value: "PLAID",
      className: "credit-img",
    },
  ];

  return (
    <>
      {loading || bills === [] ? (
        <PageLoader text />
      ) : (
        <>
          {payInvoice == 1 && (
            <div className="payment-wrap-responsive">
              <div className="header-rw-responsive">
                <div className="left-head-responsive ">
                  <Header textAlign="center">Payments</Header>
                  <p>Here’s the summary of your invoices</p>
                </div>

                <div className="button-row-responsive-container">
                  <div>

                    <Dropdown
                      button
                      floating
                      labeled
                      options={languageOptions}
                      text="Add Payment"
                      className="btn btn-primary"
                      onMouseDown={(e) => handleOnChange(e)}
                    />
                  </div>
                  <div>
                    <AutopaySetup
                      autopays={autoPays}
                      autopayOpen={autopayOpen}
                      history={props.history}
                      onAutopayOpen={handleAutopayOpen}
                      paymentOptions={paymentOptions}
                      process={process}
                      scheduleItem={scheduleItem}
                      user={user}
                    />
                  </div>
                </div>

                {isShowing && optionSelected === "Bank Account" && (
                  <BankAccountModal
                    handleAddBankAccount={handleAddBankAccount}
                    process={process}
                    showModal={isShowing}
                    toggleIsShowing={toggleIsShowing}
                  />
                )}
                {isShowing && optionSelected === "Credit Card" && (
                  <CreditCardModal
                  user={user}
                    handleAddCreditCardSubmit={handleAddCreditCardSubmit}
                    handleAddCCPLAID={handleAddCCPLAID}
                    process={process}
                    showModal={isShowing}
                    toggleIsShowing={toggleIsShowing}
                  />
                )}
                {isShowing && optionSelected === "PLAID" && (
                  <PLAIDModal
                    handleAddBankAccountPLAID={handleAddBankAccountPLAID}
                    process={process}
                    showModal={isShowing}
                    toggleIsShowing={toggleIsShowing}
                  />
                )}
              </div>
              <br></br>
              <>
                {!loading && (
                  <Grid columns="2" fluid>
                    <Grid.Column width="16">
                      <Tab
                        panes={[
                          {
                            menuItem: "Invoices",
                            render: () =>
                              bills && (
                                <Tab.Pane>
                                  {" "}
                                  <TableR
                                    bills={bills}
                                    from="Invoices"
                                    id={props}
                                    getInvoices={getInvoices}
                                    getPaymentOptions={getPaymentOptions}
                                    leaseBills={leaseBills}
                                    payInvoice={passAndDismiss}
                                    paymentOptions={
                                      paymentOptions && paymentOptions
                                    }
                                    setToPay={setToPay}
                                    user={user}
                                  />
                                </Tab.Pane>
                              ),
                          },
                          {
                            menuItem: "Saved Payments",
                            render: () =>
                              paymentOptions && (
                                <Tab.Pane>
                                  <TableR
                                    bills={bills}
                                    deletePayment={deletePayment}
                                    from="Saved"
                                    getInvoices={getInvoices}
                                    getPaymentOptions={getPaymentOptions}
                                    leaseBills={leaseBills}
                                    paymentOptions={
                                      paymentOptions && paymentOptions
                                    }
                                    process={process}
                                    updatePayment={updatePayment}
                                    user={user}
                                  />
                                </Tab.Pane>
                              ),
                          },
                          {
                            menuItem: "Autopay",
                            render: () =>
                              autoPays && (
                                <Tab.Pane>
                                  <TableR
                                    autoPays={autoPays}
                                    bills={bills}
                                    deletePayment={deletePayment}
                                    from="Autopay"
                                    getInvoices={getInvoices}
                                    getPaymentOptions={getPaymentOptions}
                                    leaseBills={leaseBills}
                                    paymentOptions={
                                      paymentOptions && paymentOptions
                                    }
                                    user={user}
                                  />
                                </Tab.Pane>
                              ),
                          },
                        ]}
                      />
                    </Grid.Column>
                    {/* detail modal */}

                    {/* delete modal */}
                    <div
                      class="modal fade payment-delete-modal"
                      id="deleteModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div class="modal-content">
                          <div class="modal-body">
                            <h5>Delete payment method</h5>
                            <p>
                              Are you sure you want delete the payment method
                              from your saved methods?
                            </p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              id="close"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => deletePersonPayment()}
                            >
                              {process ?
                                <LeaLoader wrapperStyle={{ position: 'relative', left: '-10px', bottom: '16px' }} logoStyle={{ height: '60px', width: '60px' }} />
                                :
                                'Yes, Delete'
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}
              </>
            </div>
          )}
          {payInvoice == 4 && (
            <InvoiceDetail
              setPayInvoice={setPayInvoice}
              paymentSuccessFail={paymentSuccessFail}
              flipBack={flipBack}
              setChosenMethod={setChosenMethod}
              setThePoints={setThePoints}
              setInfoStore={setInfoStore}
              setTheConcessions={setTheConcessions}
              user={user}
              getInvoices={getInvoices}
              setToPay={setToPay}
              selectedBill={selectedBill}
              setSelectedBill={setSelectedBill}
              payUsingSaved={payUsingSaved}
              paymentOptions={paymentOptions}
            />
          )}
          {payInvoice == 2 && (
            <CardPayment
              setPayInvoice={setPayInvoice}
              thePoints={thePoints}
              theConcessions={theConcessions}
              paymentSuccessFail={paymentSuccessFail}
              chosenMethod={chosenMethod}
              flipBack={flipBack}
              user={user}
              infoStore={infoStore}
              setToPay={setToPay}
              selectedBill={selectedBill}
              payUsingSaved={payUsingSaved}
              paymentOptions={paymentOptions}
            />
          )}

          {payInvoice == 3 && (
            <PaymentSuccess sucFail={sucFail} setPayInvoice={setPayInvoice} />
          )}
        </>
      )}
    </>
  );
}

export default Payments;
