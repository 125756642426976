import gql from "graphql-tag";

export const invoicesGql = gql`
  query ($id: Int!) {
    invoice(id: $id) {
      location {
        name
      }
      customer {
        nameUpper
        email
      }
      payments
      invoice {
        number
        status
        currency
        invoiceDate
        dueDate
        subtotal
        tax
        amount
      }
      items {
        subtotal
        total
        taxAmount
        description
        quantity
      }
      service {
        type
        service
      }
    }
  }
`;

export const balanceGql = gql`
  query {
    currentBalance
  }
`;



export const transactionsGql = gql`
  query {
    payments {
      edges {
        node {
          Id
          status
          amount
          paymentDate
          postedDate
          InvoiceId
        }
      }
    }
  }
`;

export const leaseBillsGql = gql`
  query Invoices {
    invoices {
      edges {
        node {
          Id
          externalId
          type
          number
          status
          amount
          amountPayable
          url
          invoiceDate
          postedDate
          dueDate
          notes
          location {
            id
          }
          approvedOn
          approvedName
          approvedEmail
          reference
          currency
          SystemJobId
          LocationId
          AgentId
          ShippingAddressId
          BillingAddressId
          ARAccountId
          TemplateId
          Location
          Agent
          ICLocation
          SalesOrder
        }
      }
    }
  }
`;
