import React, { useEffect, useState } from "react";
import { Button, Modal, Image, Grid, Header } from "semantic-ui-react";
import { MONTH_NUMS, YEAR_NUMS, WEPAY_CONFIG } from "../../utils/constants";
import LeaLoader from "../Loader/LeaLoader";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";

export function CreditCardModal(props) {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState(props&&props.user&&props.user.email);
  const [ccNumber, setCCNumber] = useState("");
  const [cvv, setCVV] = useState("");
  const [expirationMonth, setExpirationMonth] = useState(MONTH_NUMS[0]);
  const [expirationYear, setExpirationYear] = useState(YEAR_NUMS[0]);
  const [postalCode, setPostalCode] = useState("");

  const [logo, setLogo] = React.useState("");
  /* eslint-disable */
  const checkValid = () => {
    if (!userName || (userName && userName.trim() === "")) {
      return true;
    }
    if (
      !ccNumber ||
      (ccNumber && ccNumber.trim() === "") ||
      (ccNumber && ccNumber.trim().length < 13)
    ) {
      return true;
    }
    if (!cvv || (cvv && cvv.trim() === "")) {
      return true;
    }
    if (
      !postalCode ||
      (postalCode && postalCode.trim() === "") ||
      (postalCode && postalCode.trim().length < 5)
    ) {
      return true;
    } else return false;
  };
  /* eslint-enable */

  useEffect(() => {
    GetCardType();
  });

  const GetCardType = () => {
    var re = new RegExp("^4");
    if (ccNumber.match(re) != null) {
      setLogo("logo-visa.svg");
    }
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        ccNumber
      )
    ) {
      setLogo("master-logo.svg");
    }
    re = new RegExp("^3[47]");
    if (ccNumber.match(re) != null) {
      setLogo("logo-amex.svg");
    }
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (ccNumber.match(re) != null) {
      setLogo("logo-discover.svg");
    }
    re = new RegExp("^36");
    if (ccNumber.match(re) != null) {
      setLogo("logo-diners.svg");
    }
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (ccNumber.match(re) != null) {
      setLogo("logo-jcb.svg");
    }
    return "";
  };

  // useEffect(() => {
  //   window.WePay.set_endpoint("stage"); // change to "production" when live
  // }, []);

  const handleSubmit = () => {
    window.WePay.set_endpoint("stage");
    console.log({
      credit_card: {
        auto_update: false,
        card_holder: {
          address: {
            country: "US",
            postal_code: postalCode,
          },
          email: email,
          holder_name: userName,
        },
        card_number: Number(ccNumber),
        cvv: cvv,
        expiration_month: Number(expirationMonth),
        expiration_year: Number(expirationYear),
        trigger_verification: true,
        virtual_terminal_mode: "web",
        card_on_file: false,
        recurring: true,
      },
      custom_data: {},
      type: "credit_card",
    });
    window.WePay.tokens.create(
      {
        resource: "payment_methods",
        payment_methods: {
          credit_card: {
            auto_update: false,
            card_holder: {
              address: {
                country: "US",
                postal_code: postalCode,
              },
              email: email,
              holder_name: userName,
            },
            card_number: ccNumber,
            cvv: cvv,
            expiration_month: Number(expirationMonth),
            expiration_year: Number(expirationYear),
            trigger_verification: true,
            // virtual_terminal_mode: "web",
            card_on_file: true,
          },
          custom_data: {},
          type: "credit_card",
        },
      },
      {},
      function (data) {
        console.log(data);
        if (data.error_code) {
          toastFailMsg(data.details[0].message);
        } else {
          // toastSuccessMsg("Success!")
          props.handleAddCCPLAID(data.id);
        }
      }
    );
    // window.WePay.tokens.create(
    //   {
    //     client_id: WEPAY_CONFIG.WEPAY_APP_ID,
    //     user_name: userName,
    //     email: email,
    //     cc_number: ccNumber,
    //     cvv: cvv,
    //     expiration_month: expirationMonth,
    //     expiration_year: expirationYear,
    //     address: {
    //       postal_code: postalCode
    //     }
    //   },
    // function (data) {
    //   console.log(data);
    //   if (data.error) {
    //     toastFailMsg(data.error_description)
    //   } else {
    //     toastSuccessMsg("Success!")
    //   }
    // }
    // );
  };

  return (
    <Modal
      open={props.showModal}
      onClose={() => props.toggleIsShowing(false)}
      size="large"
      className="semanticModal addcredit-card-modal"
    >
      <table>
        <Modal.Header>
          Add Credit Card
          <img
            src="/assets/img/close-modal.svg"
            id="close"
            alt="close"
            onClick={() => props.toggleIsShowing(false)}
          ></img>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="card-holder">
              <input
                style={{ display: "none" }}
                type="text"
                value={email}
                name="email"
                id="email"
                className="form-control"
              ></input>
              <img src={logo && `/assets/img/${logo}`} alt=""></img>
              <div className="form-rw">
                <input
                  type="text"
                  value={
                    ccNumber.length === 15
                      ? ccNumber.replace(
                          /\b(\d{4})(\d{6})(\d{5})\b/,
                          "$1-$2-$3"
                        )
                      : ccNumber.toString().replace(/\d{4}(?=.)/g, "$&-")
                  }
                  onChange={(event) => {
                    setCCNumber(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="number"
                  id="cc-number"
                  className="form-control"
                ></input>
              </div>
              <div className="footer-data">
                <div className="left-data">
                  <label>Card Holder Name</label>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    name="name"
                    id="name"
                    className="form-control name-field"
                  ></input>
                </div>
                <div className="right-data">
                  <label>Month</label>
                  <select
                    style={{ border: "none" }}
                    name="month"
                    id="cc-month"
                    className="form-control-select"
                    value={expirationMonth}
                    onChange={(e) => setExpirationMonth(e.target.value)}
                  >
                    {Object.keys(MONTH_NUMS).map((key, val) => {
                      return (
                        <option value={MONTH_NUMS[val]}>
                          {MONTH_NUMS[val]}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="right-data">
                  <label>Year</label>
                  <select
                    style={{ border: "none" }}
                    name="year"
                    id="cc-year"
                    className="form-control-select"
                    value={expirationYear}
                    onChange={(e) => setExpirationYear(e.target.value)}
                  >
                    {Object.keys(YEAR_NUMS).map((key, val) => {
                      return (
                        <option value={YEAR_NUMS[val]}>{YEAR_NUMS[val]}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-rw">
              <div className="form-group">
                <label>Billing Zip</label>
                <input
                  type="tel"
                  value={postalCode}
                  maxlength="5"
                  onChange={(event) => {
                    setPostalCode(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="zip"
                  id="postal-code"
                  className="form-control"
                ></input>
              </div>
              <div className="form-group">
                <label>CVV</label>
                <input
                  type="tel"
                  value={cvv}
                  onChange={(event) => {
                    setCVV(event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  name="cvv"
                  id="cc-cvv"
                  className="form-control"
                ></input>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ paddingTop: "0px" }}>
          <Header
            textAlign="center"
            as="h6"
            style={{
              fontSize: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            We Accept
          </Header>
          <Grid columns="4" syle={{paddingBottom:'5px'}}>
            <Grid.Column
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              textAlign="center"
            >
              <Image style={{border: logo =="logo-visa.svg"&&`2px solid black`}} inline src={`/assets/img/logo-visa.svg`} />
            </Grid.Column>
            <Grid.Column
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              textAlign="center"
            >
              <Image
                style={{ marginTop: "5px" , border: logo =="master-logo.svg"&&`2px solid black`}}
                inline
                src={`/assets/img/master-logo.svg`}
              />
            </Grid.Column>
            <Grid.Column
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              textAlign="center"
            >
              <Image style={{border: logo =="logo-amex.svg"&&`2px solid black`}}  inline src={`/assets/img/logo-amex.svg`} />
            </Grid.Column>
            <Grid.Column
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              textAlign="center"
            >
              <Image style={{border: logo =="logo-discover.svg"&&`2px solid black`}}  inline src={`/assets/img/logo-discover.svg`} />
            </Grid.Column>
          </Grid>
          <Button
            color="black"
            disabled={checkValid() || props.process}
            type="submit"
            onClick={() => handleSubmit()}
          >
            {props.process ? (
              <LeaLoader
                wrapperStyle={{
                  position: "relative",
                  left: "-10px",
                  bottom: "16px",
                }}
                logoStyle={{ height: "60px", width: "60px" }}
              />
            ) : (
              "Add Credit Card"
            )}
          </Button>
          {/* <input
            value="Submit" id="cc-submit"
            color="black"
            disabled={checkValid() || props.process}
            type="Submit"
            onClick={() => handleSubmit()}
          /> */}
        </Modal.Actions>
      </table>
    </Modal>
  );
}

export default CreditCardModal;
