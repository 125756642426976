export const TOKEN = "token";
export const EXPIRE_TIME = "expireTime";
export const RELOAD = "reload";
export const LOGIN = "login";
export const SESSION_TIMEOUT_THRESHOLD = 300;
export const OK_200 = "200";
export const USER_EVENT = "userLoggedStatus";
export const CURRENT_TOKEN = "token";
export const NOTIFICATION = " notification";
export const RENTER = "renter";
export const MANAGER = "manager";
export const MAILING = "mailing";
export const BILLING = "billing";
export const COMPLETED = "Completed";
export const APPROVED = "Approved";
export const PROCESSING = "Processing";
export const FAILED = "Failed";
export const USER_ROLE_EVENT = "userRoleStatus";
export const SERVICEPROFESSIONAL = "serviceProfessional";
export const LEASE_RENTER = "leaseRenter";
export const CREATED_AT_DESC = "CREATED_AT_DESC";
export const TOP = 49.3457868;
export const LEFT = -124.7844079;
export const RIGHT = -66.9513812;
export const BOTTOM = 24.7433195;

export const ROUTES = {
  signup: "/signup",
};

export const PORTALS = {
  landing: "https://www.dev.leasera.com",
  search: "https://www.dev.leasera.com/renter",
};

export const DEFAULT_AVATAR = "assets/img/default_avatar.png";

export const PAYMENT_ACTION = {
  background_check: "background_check",
  lease_discount: "lease_discount",
  lease_deposit: "lease_deposit",
  lease_penalty: "lease_penalty",
  lease_payment: "lease_payment",
  reconciliation: "reconciliation",
};
export const PAYMENT_TRANS = {
  cc_charge: "cc_charge",
  cc_authorize: "cc_authorize",
  cc_reverse: "cc_reverse",
  cc_refund: "cc_refund",
  ach_send: "ach_send",
  ach_cancel: "ach_cancel",
  ach_refund: "ach_refund",
  reconciliation: "reconciliation",
};

export const APPLICATION_STATUS = {
  accepted: "Accepted",
  requires_action: "Requires Action",
  declined: "Declined",
  canceled: "Canceled",
};

export const BG_CHECK_VALID_DAYS = 60;

export const TARGET_LEASE_LENGTH = {
  one_year: "one_year",
  two_year: "two_year",
  three_year: "three_year",
  four_year: "four_year",
  five_year: "five_year",
  six_months: "six_months",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const MONTH_NUMS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
export const YEAR_NUMS = [
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];
export const DOC_EXT = {
  pdf: "Form",
  ppt: "Template",
  xls: "Sheet",
};
export const EXTENSIONS = {
  FORM: "pdf",
  TEMPLATE: "ppt",
  SHEET: "xls",
};

export const DEFAULT_PACKAGE = {
  id: "36",
  price: 49.99,
};
export const LESS_PACKAGE = {
  id: "22",
  price: 39.99,
};
export const MORE_PACKAGE = {
  id: "42",
  price: 59.99,
};

export const BG_CHECK_PRICE = 59.99;

export const VALID_CC_YEARS_FROM_NOW = 10;

export const APPLIANCES = [
  { washerMachine: "Washing Machine" },
  { dailyReception: "Daily Reception" },
  { restaurant: "Restaurant" },
  { freeWifi: "Free Wifi" },
  { parking: "Parking" },
  { nonSmokingRooms: "Non Smoking Rooms" },
  { airportShuttle: "Airport Shuttle" },
  { ac: "AC" },
  { balcony: "Balcony" },
  { heating: "Heating" },
  { pool: "Pool" },
  { hotTub: "Hot Tub" },
  { gym: "Gym" },
  { petsAllowed: "Pets Allowed" },
  { dishWasher: "Dish Washer" },
  { microwave: "Microwave" },
  { terrace: "Terrace" },
];

export const VALID_FORM_INPUT = "form-control is-valid";
export const INVALID_FORM_INPUT = "form-control is-invalid";

export const LEGAL_AGE_US = 18;
export const MAX_TENANT_ALERT_AGE = 99;
export const CHARACTER_LIMIT_SHORT = 51;
export const CHARACTER_LIMIT_MEDIUM = 201;
export const CHARACTER_LIMIT_LONG = 501;

export const MAX_PIN_LENGTH = 6;
export const MIN_PIN_LENGTH = 4;

/*eslint-disable*/
export const SOCIAL_SECURITY_REGEX =
  /^((?!000)(?!666)(?:[0-6]\d{2}|7[0-2][0-9]|73[0-3]|7[5-6][0-9]|77[0-2]))[-\s]?((?!00)\d{2})[-\s]?((?!0000)\d{4})$/;
export const ALL_COMMAS_REGEX = /,/g;
export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const PHONE_LEAD = /^(?=.*\d)[\d ]+$/;
// export const TELEPHONE_REGEX = /^[A-Za-z]+$/  deprecated for US_PHONE_REGEX
export const US_PHONE_REGEX = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
export const US_ZIPCODE_REGEX = /^\d{5}$/;
export const ALPHA_WITH_PUNC = /^[A-Za-z\s\'\-]+$/;
export const NAME_REGEX = /^[a-zA-Z]+(([\'\,\.\-][a-zA-Z ])?[a-zA-Z]*)*$/;
export const FULL_NAME_REGEX =
  /^([a-zA-Z]+[\'\,\.\-]?[a-zA-Z ]*)+[ ]([a-zA-Z]+[\'\,\.\-]?[a-zA-Z ]+)+$/;
export const DATE_REGEX =
  /^(?=\d)(?:(?:(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})))$/;
/*eslint-enable*/

export const CROPPED_IMG_DIMENSION = 400;

export const CATEGORIES = [
  "exterior",
  "electrical",
  "doors",
  "flooring",
  "heating cooling",
  "lighting",
  "locks",
  "parking lot",
  "plumbing",
  "walls",
  "general misc",
];

export const CATEGORIES_VALUES = [
  "exterior",
  "electrical",
  "doors",
  "flooring",
  "heating_cooling",
  "lighting",
  "locks",
  "parking_lot",
  "plumbing",
  "walls",
  "general_misc",
];

export const URGENCIES = ["critical", "urgent", "important"];
export const TYPES = ["repair", "replace"];
export const PRIORITY = ["Urgent", "Medium", "Low"];
export const PRIORITY_VALUES = ["Urgent", "Medium", "Low"];

export const US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
// export const US_STATES = {
//   'Alabama': 'AL',
//   'Alaska': 'AK',
//   'Arizona': 'AZ',
//   'Arkansas': 'AR',
//   'California': 'CA',
//   'Colorado': 'CO',
//   'Connecticut': 'CT',
//   'Delaware': 'DE',
//   'District of Columbia': 'DC',
//   'Florida': 'FL',
//   'Georgia': 'GA',
//   'Hawaii': 'HI',
//   'Idaho': 'ID',
//   'Illinois': 'IL',
//   'Indiana': 'IN',
//   'Iowa': 'IA',
//   'Kansas': 'KS',
//   'Kentucky': 'KY',
//   'Louisiana': 'LA',
//   'Maine': 'ME',
//   'Maryland': 'MD',
//   'Massachusetts': 'MA',
//   'Michigan': 'MI',
//   'Minnesota': 'MN',
//   'Mississippi': 'MS',
//   'Missouri': 'MO',
//   'Montana': 'MT',
//   'Nebraska': 'NE',
//   'Nevada': 'NV',
//   'New Hampshire': 'NH',
//   'New Mexico': 'NM',
//   'New York':'NY',
//   'North Carolina':'NC',
//   'North Dakota':'ND',
//   'Ohio': 'OH',
//   'Oklahoma': 'OK',
//   'Oregon': 'OR',
//   'Pennsylvania': 'PA',
//   'Rhode Island': 'RI',
//   'South Carolina':'SC',
//   'South Dakota': 'SD',
//   'Tennessee': 'TN',
//   'Texas': 'TX',
//   'Utah': 'UT',
//   'Vermont': 'VT',
//   'Virginia': 'VA',
//   'Washington': 'WA',
//   'West Virginia': 'WV',
//   'Wisconsin': 'WI',
//   'Wyoming': 'WY'
// }
export const COUNTRIES = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

export const DATE_INPUT_MIN = "1919-01-01";
export const DATE_INPUT_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_ALPHA = "DD MMM YYYY";
export const DATE_ALPHA_US = "MMM DD, YYYY";
export const DATE_MASK = "00/00/0000";
export const DATE_TIME = "MMM DD, YYYY hh:mm A";
export const TELE_MASK = "(000) 000-0000";
export const ZIP_MASK = "00000";
export const NUM_MASK = "#,##0,000";
export const TOOLTIP_POINTS =
  "Leasera works for you! Visit My Market to learn more.";
export const VIEW_APPLICATIONS = "viewApplications";
export const OPEN_APPLICATION = "openApplication";
export const MAP_DEFAULT_CENTER = { lat: 47.6062, lng: 122.3321 }; //Seattle's lat/lng

export const FAKE_BG_CHECK = {
  updated: null,
  taTimestamp: null,
  taApplicationDecision: null,
  taApplicationId: null,
  taApplicationScore: null,
  taApplicationStatus: null,
  taIsPaid: null,
  ndbId: null,
  __typename: "BackGroundCheckType",
};

export const FAKE_REQUEST = {
  urgency: null,
  ndbId: null,
  status: null,
  location: null,
  type: null,
  propertyAddress: null,
  description: null,
  submissionDate: null,
  __typename: "MaintenanceRequestType",
};

export const FAKE_PROPERTY = {
  id: null,
  startDate: null,
  endDate: null,
  primaryLessor: {
    firstName: null,
    lastName: null,
    email: null,
    photo: null,
    __typename: null,
  },
  property: {
    place: {
      photos: [
        {
          url: null,
          __typename: null,
        },
      ],
      exFromOcc: null,
      bathrooms: null,
      tax: null,
      holdingUnit: null,
      hearingAcc: null,
      visualAcc: null,
      cleaningDeposit: null,
      size: null,
      unitType: null,
      securityDeposit: null,
      leaOriginFee: null,
      mobilityAcc: null,
      updated: null,
      description: null,
      maxOccupancy: null,
      price: null,
      bedrooms: null,
      onlineMrkt: null,
      created: null,
      petsPermitted: null,
      __typename: null,
    },
    location: {
      city: null,
      streetOne: null,
      streetTwo: null,
      postcode: null,
      houseNumber: null,
      state: null,
      name: null,
      lat: null,
      lng: null,
      __typename: null,
    },
    points: null,
    __typename: null,
  },
  __typename: null,
};

// Application Specific ---------------------------------------------------
export const RESIDENCE_TAB_ID = "tab--residencies";
export const CONTACT_TAB_ID = "tab--contact";
export const BACKGROUND_TAB_ID = "tab--background";
export const REFERENCES_TAB_ID = "tab--references";
export const EMPLOYMENT_TAB_ID = "tab--employment";

// export const APPLICATION_CONTACT_FIELDS =
//   ["firstName", "lastName", "middleName", "phoneMobile", "title", "idNumber", "stateIssued", "email", "dateOfBirth"]

export const APPLICATION_CONTACT_FIELDS = [
  "firstName",
  "lastName",
  "phoneMobile",
  "email",
  "dateOfBirth",
];

export const APPLICATION_RESIDENCE_FIELDS = [
  "street",
  "city",
  "state",
  "postcode",
];

export const APPLICATION_CRIMINAL_FIELDS = [
  "isEvicted",
  "isConvictedOfFelony",
  "isConvictedOfMisdemeanor",
];

export const APPLICATION_EMPLOYMENT_ALL_FIELDS = [
  "employerName",
  "supervisorPhone",
  "supervisorEmail",
  "supervisorName",
  "salary",
  "employerEndDate",
  "employerStartDate",
  "currentTitle",
  "employerStreet",
  "employerCity",
  "employerState",
  "employerPostcode",
];

export const APPLICATION_EMPLOYMENT_FIELDS = [
  "employerName",
  "employerCity",
  "employerState",
];

export const APPLICATION_REFERENCE_FIELDS = [
  "referenceName",
  "referenceTitle",
  "referencePhone",
  "referenceEmail",
];
// --------------------------------------------------------------------------

export const TASKS = [
  "Clean garbage disposal.",
  "Clean the range hood and filter.",
  "Wash outside windows and siding.",
  "Unclog drains",
  "Test smoke detectors.",
];

export const MATERIALS = [
  "Hammer",
  "Measuring tape",
  "Screwdriver",
  "Power drill",
  "Hand saw",
  "Utility knife",
  "Five gallon bucket",
  "Step ladder",
  "Safety glasses",
];
export const COMING_SOON = "COMING SOON";
export const NUMBER_ZERO = 0;
export const NUMBER_ONE = 1;
export const NUMBER_TWO = 2;
export const NUMBER_THREE = 3;

export const BACKGROUND_CHECK_TEXT = `Please indicate the year, location and type of each felony, misdemeanor
involving a controlled substance, violence to another person or destruction of property, or a sex crime other than those
resolved by dismissal or acquittal. We may need to discuss more facts before making a decision. You represent the answer is
"no" to any item not checked above.`;

export const MARITAL_STATUS = [
  { key: "single", text: "Single", value: "Single" },
  { key: "married", text: "Married", value: "Married" },
  { key: "widowed", text: "Widowed", value: "Widowed" },
  { key: "seperated", text: "Seperated", value: "Seperated" },
];

export const UNIT_TYPE = [
  { key: "1BR/1BA", text: "1BR/1BA", value: "1BR/1BA" },
  { key: "1BR/2BA", text: "1BR/2BA", value: "1BR/2BA" },
  { key: "1BR/3BA", text: "1BR/3BA", value: "1BR/3BA" },
  { key: "2BR/1BA", text: "2BR/1BA", value: "2BR/1BA" },
  { key: "2BR/2BA", text: "2BR/2BA", value: "2BR/2BA" },
  { key: "2BR/3BA", text: "2BR/3BA", value: "2BR/3BA" },
  { key: "3BR/1BA", text: "3BR/1BA", value: "3BR/1BA" },
  { key: "3BR/2BA", text: "3BR/2BA", value: "3BR/2BA" },
  { key: "3BR/3BA", text: "3BR/3BA", value: "3BR/3BA" },
];

export const DURATION_VALUES = [
  { key: "6", text: "6 months", value: "six_months" },
  { key: "1", text: "1 year", value: "one_year" },
  { key: "2", text: "2 years", value: "two_year" },
  { key: "3", text: "3 years", value: "three_year" },
  { key: "3", text: "4 years", value: "four_year" },
  { key: "3", text: "5 years", value: "five_year" },
];

export const SERVICE_REQUIRED = [
  {
    key: "true",
    text: "Yes",
    value: "Yes",
  },
  {
    key: "false",
    text: "No",
    value: "No",
  },
];

export const NUMBERS = /^[0-9]*$/;

export const WEPAY_CONFIG = {
  WEPAY_API_BASE_URL: 'https://stage-api.wepay.com',
  WEPAY_API_ENV: 'stage',
  WEPAY_APP_ID: '472369',
  WEPAY_APP_TOKEN:
    'stage_MTg5MDRfNWFhYTA2MjItNDcwMC00ZjdjLTkwZjktNjY5Mzg4NGFmM2Vl',
  WEPAY_APP_VERSION: '3.0',
};

export const CLEAR_NOTIFICATIONS = "Mark As Read";
export const CLEAR_NOTIFICATIONS_TEXT = `Are you sure you want to mark all the notifications as read?`;

// Cloud Function Endpoints
export const BG_CHECK_URL = `https://service-background-renter-ywkd3aaksq-uc.a.run.app/background_renter/graphql`;
export const DOCUMENTS_URL = `https://service-organization-ywkd3aaksq-uc.a.run.app/organization/graphql`;
export const PAYMENTS_URL = `https://payment-dev-dot-leasera-${process.env.REACT_APP_DEVELOP}.uc.r.appspot.com/graphql`;
export const PERSON_URL = `https://service-person-ywkd3aaksq-uc.a.run.app/person/graphql`;
export const PERSON_IMAGE_URL = `https://service-person-ywkd3aaksq-uc.a.run.app/person`;
export const APPLICATIONS_URL = `https://service-application-renter-ywkd3aaksq-uc.a.run.app/application_renter/graphql`;
export const INVOICES_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEVELOP}.cloudfunctions.net/invoices`;
export const MAINTENANCE_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEVELOP}.cloudfunctions.net/mr_renter`;
export const LEASES_URL = `https://service-lease-renter-ywkd3aaksq-uc.a.run.app/lease_renter/graphql`;
export const PRIMARY_LOCATION_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEVELOP}.cloudfunctions.net/primary_location`;
export const WAITLIST_URL = `https://service-renter-waitlist-ywkd3aaksq-uc.a.run.app/renter_waitlist/graphql`;
export const TOURS = `https://service-lead-tour-renter-ywkd3aaksq-uc.a.run.app/lead_tour_renter/graphql`;
export const TOUR_TYPE = `https://service-lead-tour-type-ywkd3aaksq-uc.a.run.app/lead_tour_type/graphql`;
export const AFFORDABLE_HOUSING = `https://service-affordable-housing-ywkd3aaksq-uc.a.run.app/affordable_housing/graphql`;
export const APPLICATION_RENTER = `https://service-application-renter-ywkd3aaksq-uc.a.run.app/application_renter/graphql`;
export const UNITAPI = `https://service-unit-ywkd3aaksq-uc.a.run.app/unit/graphql`;
export const UNIT_TYPE_API = `https://service-unit-type-ywkd3aaksq-uc.a.run.app/unit_type/graphql`;
export const SEARCH_PROP = `https://service-location-search-ywkd3aaksq-uc.a.run.app/location_search/graphql`;
export const LOCATION_UTILS = `https://service-location-utils-ywkd3aaksq-uc.a.run.app/location_utils/graphql`;
export const TA_SUBMIT = `https://service-background-renter-ywkd3aaksq-uc.a.run.app/background_renter/graphql`;
export const POINTS = `https://service-display-loyalty-ledger-ywkd3aaksq-uc.a.run.app/display_loyalty_ledger/graphql`;
export const MARKETING = `https://service-market-ywkd3aaksq-uc.a.run.app/marketing/graphql`;
export const DOCUSIGN = `https://us-central1-leasera-${process.env.REACT_APP_DEVELOP}.cloudfunctions.net/docu_sign`
export const BOOKING_TRIPS = `https://service-booking-com-ywkd3aaksq-uc.a.run.app/booking_com/graphql`
export const WEPAY_URL = `https://dev-dot-leasera-${process.env.REACT_APP_DEVELOP}.uc.r.appspot.com/graphql`;
export const RECEIPTS_URL = `https://dev-dot-leasera-${process.env.REACT_APP_DEVELOP}.uc.r.appspot.com/receipt`;
const CHAT_URL_DEV = `https://service-chat-ywkd3aaksq-uc.a.run.app/chat/graphql`;
const CHAT_URL_STAGING = `https://service-chat-cg2k2cekgq-uc.a.run.app/chat/graphql`;
const CHAT_URL_PRODUCTION = `https://service-chat-oudf2y2rza-uc.a.run.app/chat/graphql`;
export const GIFT_EXCHANGE_URL = `https://service-gift-exchange-ywkd3aaksq-uc.a.run.app/gift_exchange/graphql`;
export const LEASE_RENTER_LOCAL = 'https://service-lease-renter-ywkd3aaksq-uc.a.run.app/lease_renter?lease_id=';

// export const RENTERTOLOCATION = `https://us-central1-leasera-${process.env.REACT_APP_DEVELOP}.cloudfunctions.net/renter_to_location`;
const CHAT_URL_IMAGE_PATH_DEV =
  "https://service-chat-ywkd3aaksq-uc.a.run.app/chat?path=";
const CHAT_URL_IMAGE_PATH_STAGING =
  "https://service-chat-cg2k2cekgq-uc.a.run.app/chat?path=";
const CHAT_URL_IMAGE_PATH_PRODUCTION =
  "https://service-chat-oudf2y2rza-uc.a.run.app/chat?path=";

let CHAT_URL = CHAT_URL_DEV;
let CHAT_URL_IMAGE_PATH = CHAT_URL_IMAGE_PATH_DEV;

if (process.env.REACT_APP_STAGING === "staging") {
  CHAT_URL = CHAT_URL_STAGING;
  CHAT_URL_IMAGE_PATH = CHAT_URL_IMAGE_PATH_STAGING;
}
if (process.env.REACT_APP_PRODUCTION === "production") {
  CHAT_URL = CHAT_URL_PRODUCTION;
  CHAT_URL_IMAGE_PATH = CHAT_URL_IMAGE_PATH_PRODUCTION;
}
if (process.env.REACT_APP_DEVELOP === "200719") {
  CHAT_URL = CHAT_URL_DEV;
  CHAT_URL_IMAGE_PATH = CHAT_URL_IMAGE_PATH_DEV;
}

export { CHAT_URL, CHAT_URL_IMAGE_PATH };
